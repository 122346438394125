import { Inline, PadBox } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _capitalize from 'lodash/capitalize';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { IoMdEyeOff } from 'react-icons/io';
import { IoEye } from 'react-icons/io5';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Spinner,
  TextButton,
  Typography,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { subscriptionPlanAtom } from '../../../atom';
import { useAxiosPrivate } from '../../../hooks';
import { RoleList } from '../../../pages/Workspace/component/Team/RoleList';
import { MemberStatus } from '../../../pages/Workspace/component/Team/WorkspaceMemberList.styled';
import type {
  Role,
  RoleDetails,
} from '../../../pages/Workspace/component/types';
import { useGetEntityMemberList } from '../../../pages/Workspace/hooks/restApi/useGetEntityMemberList';
import { getUserAvatar, getUserName } from '../../../pages/Workspace/utils';
import { SubscriptionPlanType } from '../../../types';
import { ProfileIconContainer } from '../../Header/components/profile/ProfileIcon.styled';
import { EntityInfoType } from '../../VersionControl/VersionControl';
import { getEntityTypeForApi } from '../utils/common';
import { AccessUpdateWarningModal } from './AccessUpdateWarningModal';
import {
  AccessDetailsCell,
  AccessDetailsContainer,
  AccessHeader,
  AccessHeaderContainer,
  AccessTextContainer,
  EmptyState,
  EntityAccessContainer,
  VerticalLine,
} from './EntityLevelAccessModal.styled';

type EntityLevelAccessModalProps = {
  entityInfo?: EntityInfoType;
  updateListingData?: () => void;
};

export type UpdatedAccessObj = Record<
  string,
  {
    role: Role;
    status?: boolean;
  }
>;

const defaultEntityInfo = {
  name: '',
  id: '',
  type: '',
  status: '',
  subType: '',
  accessRole: '',
};

export function EntityLevelAccessModal({
  entityInfo,
  updateListingData,
}: EntityLevelAccessModalProps) {
  const {
    name = '',
    type,
    id,
    accessRole = '',
  } = entityInfo ?? defaultEntityInfo;

  const [updatedAccessObj, setUpdatedAccesObj] = useState<UpdatedAccessObj>({});
  const [isAccessOveridden, setIsAccessOveridden] = useState(false);

  const [, setSubscriptionPlan] = useAtom(subscriptionPlanAtom);

  const { axiosPrivate } = useAxiosPrivate();
  const { close } = useCurrentLayer();

  const {
    data: entityMemberListData,
    isLoading,
    getEntityMemberList,
  } = useGetEntityMemberList();

  const { openWithProps: openWarningModal } = useLayer(
    <AccessUpdateWarningModal />
  );

  useEffect(() => {
    if (!_isNil(entityMemberListData) && !_isEmpty(entityMemberListData)) {
      setIsAccessOveridden(
        entityMemberListData.find(
          (details) => details.workspaceRole !== details.entityRole
        ) != null
      );
    }
  }, [JSON.stringify(entityMemberListData)]);

  useEffect(() => {
    if (!_isNil(id) && !_isEmpty(id) && !_isEmpty(type) && !_isEmpty(type)) {
      void getEntityMemberList({
        entityType: getEntityTypeForApi(type),
        id,
      });
    }
  }, [type, id]);

  const getAccountUsageDetails = async () => {
    try {
      const response = await axiosPrivate.get<{
        data: SubscriptionPlanType;
      }>(`/plan/usage`);

      if (!_isNil(response.data.data)) {
        setSubscriptionPlan({
          ...response.data.data,
          planName: response.data.data.plan.name,
        });
      }
    } catch (err) {}
  };

  const onUpdateRole = (roleDetails: RoleDetails, id?: string) => {
    if (!_isNil(id)) {
      setUpdatedAccesObj((prev) => ({
        ...prev,
        [id]: {
          role: roleDetails.value,
        },
      }));
    }
  };

  const onRemoveClicked = (memberId?: string) => {
    if (!_isNil(memberId)) {
      setUpdatedAccesObj((prev) => ({
        ...prev,
        [memberId]: {
          role: 'restricted',
        },
      }));
    }
  };

  const handleAccessUpdateError = () => {
    if (typeof updateListingData === 'function') {
      updateListingData();
    }
    void getAccountUsageDetails();
    close();
  };

  if (_isNil(entityInfo)) {
    return null;
  }

  return (
    <Modal
      size="extraLarge"
      title={
        <PadBox padding="1.5rem">
          <Typography name="heading2">{`Edit access of ${name}`}</Typography>
        </PadBox>
      }
      overflow="none"
      hideCloseButton={true}
      padding="0rem"
    >
      <ModalContent>
        <EntityAccessContainer gutter="0">
          <AccessTextContainer justify="start" align="center" gutter="1rem">
            {isAccessOveridden ? (
              <>
                <IoMdEyeOff color="var(--color-darkGray)" />
                <Typography name="secondarySmall">
                  Access overridden. Role may vary from workspace team settings.
                </Typography>
              </>
            ) : (
              <>
                <IoEye />
                <Typography name="secondarySmall">
                  Access share to everyone in workspace
                </Typography>
              </>
            )}
          </AccessTextContainer>
          <Stack gutter={0}>
            <AccessHeaderContainer>
              <AccessHeader align="center" $width="40%">
                Username
                <VerticalLine />
              </AccessHeader>
              <AccessHeader align="center" $width="30%">
                Workspace Role
                <VerticalLine />
              </AccessHeader>
              <AccessHeader $width="30%">Entity Role</AccessHeader>
            </AccessHeaderContainer>

            {!_isNil(entityMemberListData) &&
              entityMemberListData.length > 0 &&
              !isLoading &&
              entityMemberListData.map((currDetail) => {
                const {
                  email,
                  firstName,
                  lastName,
                  uuid,
                  workspaceRole,
                  entityRole,
                  isInvitationVerified,
                } = currDetail;

                const memberName = getUserName(firstName, lastName);
                const memberAvatar = getUserAvatar(firstName, lastName, email);

                const role = Object.prototype.hasOwnProperty.call(
                  updatedAccessObj,
                  uuid
                )
                  ? updatedAccessObj[uuid].role
                  : entityRole;

                return (
                  <AccessDetailsContainer key={uuid}>
                    <AccessDetailsCell $width="40%">
                      <Inline gutter="1rem" align="center">
                        <ProfileIconContainer
                          align="center"
                          justify="center"
                          cursor="auto"
                        >
                          <Typography>{memberAvatar}</Typography>
                        </ProfileIconContainer>

                        <Stack gutter={0}>
                          <Inline align="center" gutter="1rem">
                            <Typography fontWeight={700}>
                              {memberName}
                            </Typography>
                            {!isInvitationVerified && (
                              <MemberStatus>Pending</MemberStatus>
                            )}
                          </Inline>
                          <Typography>{email}</Typography>
                        </Stack>
                      </Inline>
                      <VerticalLine />
                    </AccessDetailsCell>

                    <AccessDetailsCell $width="30%" align="center">
                      <Typography className="workspace-role" name="secondary2">
                        {workspaceRole}
                      </Typography>
                      <VerticalLine />
                    </AccessDetailsCell>

                    <AccessDetailsCell $width="30%" align="center">
                      <RoleList
                        id={uuid}
                        launcher={
                          <Button
                            appearance="bordered-black"
                            disabled={
                              role === 'owner' ||
                              !['owner', 'admin'].includes(accessRole)
                            }
                          >
                            {role === 'restricted'
                              ? 'Access Denied'
                              : _capitalize(role)}
                          </Button>
                        }
                        onClickRole={onUpdateRole}
                        placement="bottom-end"
                        onRemoveMember={onRemoveClicked}
                        role={role}
                        rolesToHide={['restricted', 'tech']}
                        disabled={!['owner', 'admin'].includes(accessRole)}
                      >
                        <TextButton appearance="danger">
                          Access Denied
                        </TextButton>
                      </RoleList>
                    </AccessDetailsCell>
                  </AccessDetailsContainer>
                );
              })}

            {isLoading && (
              <EmptyState justify="center">
                <Spinner />
              </EmptyState>
            )}
          </Stack>
        </EntityAccessContainer>
      </ModalContent>
      <ModalFooter hasBgColor={true}>
        <PadBox as={Inline} gutter="1rem" padding="1rem">
          <Button appearance="neutralWhite" onClick={close}>
            Close
          </Button>
          <Button
            appearance="contained2"
            disabled={_isNil(updatedAccessObj) || _isEmpty(updatedAccessObj)}
            onClick={() =>
              openWarningModal({
                entityAccessData: {
                  entityId: id,
                  entity: type,
                  accessRoles: updatedAccessObj,
                },
                handleAccessUpdateError,
              })
            }
          >
            Update access
          </Button>
        </PadBox>
      </ModalFooter>
    </Modal>
  );
}
