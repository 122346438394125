import { Inline, Stack } from '@bedrock-layout/primitives';
import { atom, useAtom } from 'jotai';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { BiCopy } from 'react-icons/bi';
import {
  Button,
  CheckboxField,
  DateField,
  Expander,
  IconButton,
  TextField,
  TextInput,
  Toast,
  TooltipReact,
  Typography,
  useLayer,
} from 'ui';

import { siteConstantsAtom } from '../../../../atom';
import { getValueFromObject } from '../../../../components/Listing/utils';
import { TabSelectionApiHook } from '../../../../components/TabSelectionApiHook/TabSelectionApiHook';
import {
  copyToClipboard,
  getTooltipText,
  handleChangeApiToWebhookUrl,
} from '../../../../utils/common';
import { isRuleReadOnlyAtom } from '../../index';
import {
  enableCronTestButtonAtom,
  ruleEnvironmentAtom,
} from '../CreateRuleSheet/CreateRuleSheet';
import { RuleCronScheduleModel } from '../SimpleRule/models';
import { cronInfoAtom } from '../atom/atom';
import { ScheduleItem } from './Scheduler/ScheduleItem';
import { ScheduleTestSheet } from './Scheduler/ScheduleTestSheet';
import { Scheduler } from './Scheduler/Scheduler';
import { TriggerMenu } from './TriggerMenu/TriggerMenu';
import { ScheduleCronText } from './Triggers.styled';

export type TriggerFormProps = Omit<UseControllerProps, 'name'> & {
  type: 'staging' | 'production';
  setValue?: UseFormSetValue<any>;
};

export const openSchedularAtom = atom<boolean>(false);

export function TriggerForm({ control, type, setValue }: TriggerFormProps) {
  const fieldNamePrefix =
    type === 'staging' ? 'stagingConfig' : 'productionConfig';

  const [cronInfo] = useAtom(cronInfoAtom);

  const [, setAutoOpenScheduler] = useAtom(openSchedularAtom);

  const staticUrl: string = useWatch({
    control,
    name: `${fieldNamePrefix}.staticUrl`,
  });

  const status: string = useWatch({
    control,
    name: `status`,
  });

  const schedule: RuleCronScheduleModel[] | null = useWatch({
    name: 'productionConfig.schedule',
    control,
  });
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [siteConstant] = useAtom(siteConstantsAtom);
  const [enableCronTest] = useAtom(enableCronTestButtonAtom);
  const [, setRuleSetEnvironment] = useAtom(ruleEnvironmentAtom);

  const { open } = useLayer(
    <ScheduleTestSheet cronIndex={0} parentControl={control} />
  );

  const cronStatusBlock = useMemo(() => {
    if (cronInfo?.cronStatus === 'published') {
      return (
        <Typography name="paragraphSmall">
          Schedule may take upto 30 seconds to reflect in your setup
        </Typography>
      );
    }

    if (cronInfo?.status === 'Failed') {
      return (
        <Toast type="error" message="Failed to schedule, please try again" />
      );
    }

    return null;
  }, [cronInfo]);

  const handleOpenScheduler = () => {
    setRuleSetEnvironment('production');
    setAutoOpenScheduler(true);
  };

  const plan = JSON.parse(window.sessionStorage.getItem('userPlan') ?? '{}');

  return (
    <Stack gutter="2rem">
      <Expander fontWeight={700} title="Trigger">
        <Stack gutter={16}>
          <Stack>
            <Inline stretch="start">
              <Inline align="center" gutter={8}>
                <Typography name="paragraphSmall" fontWeight={700}>
                  API
                </Typography>

                <TooltipReact id="api-trigger" placement="bottom-start">
                  <Typography>
                    {getTooltipText(siteConstant, 'rules', 'triggerApi')}
                  </Typography>
                </TooltipReact>
              </Inline>

              <CheckboxField
                useId={`${fieldNamePrefix}.isApiEnabled`}
                name={`${fieldNamePrefix}.isApiEnabled`}
                appearance="switch"
                control={control}
                disabled={isRuleReadOnly}
              />
            </Inline>
            <TabSelectionApiHook>
              <Inline stretch="start" align="center">
                <TextField
                  control={control}
                  name={`${fieldNamePrefix}.staticUrl`}
                  disabled
                  icon={
                    <IconButton
                      onClick={async () =>
                        await copyToClipboard(staticUrl, 'API URL copied')
                      }
                    >
                      <BiCopy />
                    </IconButton>
                  }
                />
                <TriggerMenu control={control} type={type} />
              </Inline>

              <Inline stretch="start" align="center">
                <TextInput
                  disabled
                  value={handleChangeApiToWebhookUrl(
                    staticUrl,
                    status === 'published' ? 'production' : 'staging'
                  )}
                  icon={
                    <IconButton
                      onClick={async () =>
                        await copyToClipboard(
                          handleChangeApiToWebhookUrl(
                            staticUrl,
                            status === 'published' ? 'production' : 'staging'
                          ),
                          'Webhook URL copied'
                        )
                      }
                    >
                      <BiCopy />
                    </IconButton>
                  }
                />
                <TriggerMenu control={control} type={type} />
              </Inline>
            </TabSelectionApiHook>
          </Stack>
        </Stack>
      </Expander>
      <Expander fontWeight={700} title="Settings">
        <Stack gutter={16}>
          <Inline justify="start" stretch="all">
            <Stack gutter={4}>
              <Inline gutter={8} align="center">
                <Typography name="paragraphSmall" fontWeight={700}>
                  Start Date
                </Typography>
                <TooltipReact id="trigger-start-date" placement="bottom-end">
                  <Typography>
                    {getTooltipText(siteConstant, 'rules', 'triggerStartDate')}
                  </Typography>
                </TooltipReact>
              </Inline>

              <DateField
                control={control}
                name={`${fieldNamePrefix}.startDate`}
                disabled={isRuleReadOnly}
              />
            </Stack>

            <Stack gutter={4}>
              <Inline gutter={8} align="center">
                <Typography name="paragraphSmall" fontWeight={700}>
                  End Date
                </Typography>
                <TooltipReact id="trigger-end-date" placement="bottom-start">
                  <Typography>
                    {getTooltipText(siteConstant, 'rules', 'triggerEndDate')}
                  </Typography>
                </TooltipReact>
              </Inline>

              <DateField
                control={control}
                name={`${fieldNamePrefix}.endDate`}
                disabled={isRuleReadOnly}
              />
            </Stack>
          </Inline>
          <Inline stretch="start">
            <Inline align="center" gutter={8}>
              <Typography name="paragraphSmall" fontWeight={700}>
                Status
              </Typography>

              <TooltipReact id="status-trigger" placement="bottom-start">
                <Typography>
                  {getTooltipText(siteConstant, 'rules', 'triggerStatus')}
                </Typography>
              </TooltipReact>
            </Inline>

            <CheckboxField
              useId={`${fieldNamePrefix}.isEnabled`}
              name={`${fieldNamePrefix}.isEnabled`}
              appearance="switch"
              control={control}
              disabled={isRuleReadOnly}
            />
          </Inline>
          <Inline stretch="start">
            <Inline align="center" gutter={8}>
              <Typography name="paragraphSmall" fontWeight={700}>
                Auditing
              </Typography>

              <TooltipReact id="auditing-trigger" placement="bottom-start">
                <Typography>
                  {getTooltipText(siteConstant, 'rules', 'auditing')}
                </Typography>
              </TooltipReact>
            </Inline>

            <CheckboxField
              useId={`${fieldNamePrefix}.auditIO`}
              name={`${fieldNamePrefix}.auditIO`}
              appearance="switch"
              control={control}
              disabled={isRuleReadOnly}
            />
          </Inline>
        </Stack>
      </Expander>
      <Expander
        fontWeight={700}
        title="Schedule"
        tooltip="Test your schedule in staging and make it live in production"
      >
        <Stack>
          {type !== 'staging' && (schedule ?? [])?.length === 0 && (
            <Scheduler control={control} setValue={setValue} />
          )}

          {type !== 'staging' &&
            (schedule ?? []).map((sch, i) => (
              <Stack key={`element_${i}`} gutter="1rem">
                <ScheduleItem setValue={setValue} control={control} index={i} />

                <Scheduler
                  control={control}
                  setValue={setValue}
                  cronIndex={i}
                  key={`field_schedule_${i}`}
                  launcher={
                    <Button
                      disabled={isRuleReadOnly}
                      appearance="filled"
                      id={getValueFromObject(plan, 'plan.cron.componentId')}
                      data-premium-component-id={getValueFromObject(
                        plan,
                        'plan.cron.componentId'
                      )}
                      data-premium-component-trigger={getValueFromObject(
                        plan,
                        'plan.cron.trigger'
                      )}
                    >
                      Edit Schedule
                    </Button>
                  }
                />

                {cronInfo?.cronStatus === 'notPublished' && (
                  <Toast
                    type="warning"
                    message="To schedule your cron in production please test and publish the
                  rule"
                  />
                )}

                <span>{cronStatusBlock}</span>
              </Stack>
            ))}

          {type !== 'staging' && (schedule ?? []).length > 0 && (
            <Stack>
              <Button
                appearance={
                  !enableCronTest || isRuleReadOnly ? 'filled' : 'contained'
                }
                onClick={open}
                disabled={!enableCronTest || isRuleReadOnly}
              >
                Test Schedule
              </Button>

              {enableCronTest && !isRuleReadOnly && (
                <Inline justify="start" align="center" gutter={'0.3rem'}>
                  <Typography>To schedule your cron</Typography>
                  <div onClick={handleOpenScheduler}>
                    <ScheduleCronText>click here</ScheduleCronText>
                  </div>
                </Inline>
              )}
            </Stack>
          )}

          {status !== 'published' && (
            <Toast
              message="The changes in the settings will be live when rule is published to production"
              type="warningBlue"
            />
          )}
        </Stack>
      </Expander>
    </Stack>
  );
}
