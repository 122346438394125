import { atom } from 'jotai';
import Cookies from 'js-cookie';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { ReactNode, createContext, useContext, useDebugValue } from 'react';
import useLocalStorage from 'react-localstorage-hook';
import { useSearchParams } from 'react-router-dom';

import type { RoleJsonType } from '../../../types';

export type AccessToken = string;
export type RefreshToken = string;
export type Tokens = {
  accessToken: AccessToken;
  refreshToken: RefreshToken;
  workspaceUUID?: string;
  userUUID?: string;
};

export type AuthContextValue = {
  auth: Tokens;
  setAuth: ({ accessToken, refreshToken }: Tokens) => void;
  rememberMe: boolean;
  setRememberMe: (remember: boolean) => void;
  isLoggedIn: boolean;
};

const AuthContext = createContext<AuthContextValue>({
  auth: { accessToken: '', refreshToken: '' },
  setAuth: () => {},
  rememberMe: true,
  setRememberMe: () => {},
  isLoggedIn: false,
});

type AuthProviderProps = {
  children: ReactNode;
};

export const roleJsonAtom = atom<RoleJsonType>({});
export const roleMapAccessAtom = atom<Record<string, RoleJsonType>>({});

export function AuthProvider({ children }: AuthProviderProps) {
  const [refreshToken, setRefreshToken] = useLocalStorage('refreshToken', '');
  const [rememberMe, setRememberMe] = useLocalStorage('rememberMe', true);
  let isLoggedIn = !_isEmpty(window.localStorage.getItem('accessToken'));
  const accessToken = window.localStorage.getItem('accessToken') ?? '';
  const [searchParams] = useSearchParams();

  const value = {
    auth: {
      accessToken,
      refreshToken,
    },
    setAuth: ({
      accessToken,
      refreshToken,
      workspaceUUID,
      userUUID,
    }: Tokens) => {
      const allowedDomain = '.nected.ai;.nected.io';
      window.localStorage.setItem('accessToken', accessToken);
      Cookies.set('nected_at', accessToken, { domain: allowedDomain });

      if (!_isEmpty(workspaceUUID) && !_isNil(workspaceUUID)) {
        window.sessionStorage.setItem('workspaceUUID', workspaceUUID);
        window.localStorage.setItem('workspaceUUID', workspaceUUID ?? '');
        Cookies.set('nected_wsid', workspaceUUID ?? '', {
          domain: allowedDomain,
        });
      }

      if (!_isEmpty(userUUID) && !_isNil(userUUID)) {
        window.localStorage.setItem('userUUID', userUUID);
        Cookies.set('nected_uid', userUUID, { domain: allowedDomain });
      }

      window.localStorage.setItem('currentLang', 'en');
      Cookies.set('nected_lang', 'en', { domain: allowedDomain });

      if (!_isNil(searchParams.get('sourceRedirect'))) {
        window.localStorage.setItem(
          'sourceRedirect',
          searchParams.get('sourceRedirect') ?? ''
        );
      }
      isLoggedIn = !_isEmpty(accessToken);
      setRefreshToken(refreshToken);
    },
    rememberMe,
    setRememberMe,
    isLoggedIn,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);
  useDebugValue(context.isLoggedIn, (isLoggedIn) =>
    isLoggedIn ? 'Logged In' : 'Logged Out'
  );

  return context;
}
