import { z } from 'zod';

const ruleListSchema = z
  .object(
    {
      ruleId: z.string().nullable(),
      isEnabled: z.boolean(),
    },
    {
      invalid_type_error: 'Please select a rule',
    }
  )
  .nullable()
  .array();

export const rulesetValidationSchema = z
  .object({
    ruleName: z.string().min(1, 'Rule name cannot be empty'),
    ruleDescription: z
      .string()
      .max(1000, 'Rule description cannot be more than 1000 characters'),
    rulePolicy: z
      .object({
        label: z.string(),
        value: z.string(),
      })
      .nullable(),
    ruleList: ruleListSchema,

    productionConfig: z.object({
      startDate: z.nullable(z.date()),
      endDate: z.nullable(z.date()),
      api: z.string(),
      isEnabled: z.boolean(),
      auditIO: z.boolean(),
      authType: z.nullable(
        z.object({
          label: z.string(),
          value: z.string(),
        })
      ),
      schedule: z.any(),
      isApiEnabled: z.boolean(),
    }),
  })
  .refine(
    (data) => {
      if (
        data.productionConfig.endDate != null &&
        data.productionConfig.startDate != null
      ) {
        const startDate = new Date(data.productionConfig.startDate);
        const endDate = new Date(data.productionConfig.endDate);

        return startDate <= endDate;
      }

      return true;
    },
    {
      path: ['productionConfig.endDate'],
      message: 'End Date must be greater than or equal to start date',
    }
  );
