import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import {
  type UseControllerProps,
  type UseFormSetValue,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import { FaPlus } from 'react-icons/fa6';
import { MdEdit } from 'react-icons/md';
import { Button, Typography, useLayer } from 'ui';

import { siteConstantsAtom } from '../../../../../atom';
import {
  DynamicPanelContainer,
  EditorPanelContainer,
  SidePanelContainer,
} from '../../../../../components/CommonStyles/CommonStyles.styled';
import { EntityDependencyUsage } from '../../../../../components/EntityDependencyUsage/EntityDependencyUsage';
import { EntityUnderReview } from '../../../../../components/EntityUnderReview/EntityUnderReview';
import { FAQMenu } from '../../../../../components/FAQMenu';
import { getValueFromObject } from '../../../../../components/Listing/utils';
import { Panel } from '../../../../../components/Panel/Panel';
import { VersionControl } from '../../../../../components/VersionControl/VersionControl';
import { tabList } from '../../../../../components/VersionControl/constant';
import { StackAsItem } from '../../../../../components/layouts/Stack.styled';
import { CustomAttributeSheet } from '../../../../../components/rules/forms/CustomAttributeSheet';
import { useSendEventToGTM } from '../../../../../hooks/useSendEventToGTM';
import {
  getTooltipText,
  handleSetCheckSumByEntityName,
} from '../../../../../utils/common';
import { ENTITY_ID } from '../../../../../utils/constant';
import { useUpdateStateOnRulePublish } from '../../../hooks/useUpdateStateOnRulePublish';
import type { sendEventToGTMType } from '../../../types';
import { getRequiredKey } from '../../../utils/common';
import { RulesContainerStyled } from '../../CommonStyles/CommonStyles.styled';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { Triggers } from '../../Triggers/Triggers';
import {
  activePanelAtom,
  approvalInfoRuleAtom,
  assetsInDraftMapAtom,
  hasConnectorErrorInCustomAttrSheetAtom,
  vcListTabIndexRuleAtom,
  versionInfoRuleAtom,
  versionMappingInfoAtom,
} from '../../atom/atom';
import { AdditionalOutputData } from '../components/AdditionalOutputData';
import { DataAddActionDecisionTable } from '../components/DataAddActionDecisionTable';
import { DecisionTableBlock } from '../components/DecisionTableBlock';
import { DecisionTableConditionStructure } from '../models';
import { DecisionTableResultRow } from '../types';
import {
  CustomInputContainer,
  HeaderActionsContainer,
  RuleEditorContainer,
  RuleInputHeader,
  RuleNodesContainer,
} from './DecisionTableEditor.styled';

export type DecisionTableEditorProps = Omit<UseControllerProps, 'name'> & {
  isReadOnly: boolean;
  setValue: UseFormSetValue<any>;
  discardRule: () => void;
  scrollStarted: boolean;
  isClone: boolean;
  handleFetchRule: () => void;
  ruleId?: string;
};
export type BoundingClientRect = {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
};
export type Dimensions = BoundingClientRect | null; // Assuming BoundingClientRect is your type

export const DecisionTableEditor = ({
  control,
  isReadOnly,
  setValue,
  discardRule,
  ruleId,
  scrollStarted,
  isClone,
  handleFetchRule,
}: DecisionTableEditorProps) => {
  const [activePanel, setActivePanel] = useAtom(activePanelAtom);
  const [vcListTabIndex, setVcListTabIndex] = useAtom(vcListTabIndexRuleAtom);
  const [approvalInfoRule, setApprovalInfoRule] = useAtom(approvalInfoRuleAtom);
  const [versionInfoRule] = useAtom(versionInfoRuleAtom);

  const [siteConstants] = useAtom(siteConstantsAtom);
  const [hasConnectorErrCustomAttrSheet] = useAtom(
    hasConnectorErrorInCustomAttrSheetAtom
  );
  const [assetsInDraftMap, setAssetsInDraftMap] = useAtom(assetsInDraftMapAtom);
  const [versionMappingInfoRule] = useAtom(versionMappingInfoAtom);

  const [dataset] = useAtom(dataSetParamsAtom);
  const { attributes } = dataset.customInput;

  const { openWithProps: openCustomAttributeSheet } = useLayer(
    <CustomAttributeSheet isReadOnly={isReadOnly} />
  );

  const { sendEventToGTM } = useSendEventToGTM();

  const plan = JSON.parse(window.sessionStorage.getItem('userPlan') ?? '{}');

  const { fields } = useFieldArray({
    name: 'rows',
    control,
  });

  const ruleName = useWatch({
    control,
    name: 'ruleName',
  });

  const status = useWatch({
    control,
    name: 'status',
  });

  const accessRoleValue = useWatch({
    control,
    name: 'accessRole',
  });

  const hasCustomAttributes =
    _filter(attributes, (value, key) => {
      return !_isNil(attributes[key].dataType);
    }).length > 0;

  const getColumnCount = () => {
    if (fields.length > 0) {
      const rowKey = getRequiredKey(fields[0], ['id']);

      const currentCondition: DecisionTableConditionStructure[] = useWatch({
        name: `rows.${0}.${rowKey}.condition`,
        control,
      });

      const currentResults: DecisionTableResultRow[] = useWatch({
        name: `rows.${0}.${rowKey}.ruleResult`,
        control,
      });

      return (currentCondition?.length ?? 0) + (currentResults?.length ?? 0);
    }

    return 0;
  };

  const rowCount = fields.length;
  const columnCount = getColumnCount();

  const handleSendEventToGTM = ({
    action = '',
    element = '',
    actionName = '',
  }: sendEventToGTMType) => {
    sendEventToGTM({
      event: 'rule',
      ruleId,
      ruleName,
      type: 'decisionTable',
      nec_source: '',
      action,
      element,
      action_name: actionName,
    });
  };

  const { handleAfterRuleIsPublished } = useUpdateStateOnRulePublish({
    id: ruleId ?? '',
    ruleName: ruleName ?? '',
    isClone,
    setValue,
  });

  const tableInfoContainer = document.querySelector(
    '.decision-rule-table-info-container'
  ) as HTMLDivElement;
  const editorContainer = document.querySelector(
    '.decision-rule-editor-container'
  ) as HTMLDivElement;

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', calculateTableInfoWidth);
    };
  }, []);

  useEffect(() => {
    const updatedVcListTabIndex = tabList.findIndex(
      (tabObj) => tabObj.value === status
    );
    setVcListTabIndex(updatedVcListTabIndex !== -1 ? updatedVcListTabIndex : 0);
  }, [status]);

  useEffect(() => {
    setAssetsInDraftMap(undefined);
  }, [versionMappingInfoRule]);

  useEffect(() => {
    calculateTableInfoWidth();
  }, [activePanel]);

  useEffect(() => {
    if (!_isNil(editorContainer) && !_isNil(tableInfoContainer)) {
      calculateTableInfoWidth();

      window.addEventListener('resize', calculateTableInfoWidth);
    }
  }, [tableInfoContainer, editorContainer]);

  const calculateTableInfoWidth = () => {
    if (!_isNil(editorContainer) && !_isNil(tableInfoContainer)) {
      const width = editorContainer.getBoundingClientRect().width;
      tableInfoContainer.style.width = String(width - 4) + 'px';
    }
  };

  const handleEntityUpdate = (data: Record<string, any>) => {
    if (!_isNil(setValue) && !_isNil(data)) {
      Object.keys(data).forEach((key) => {
        if (key === 'checksum') {
          handleSetCheckSumByEntityName('rule', data.checksum);
        } else if (key === 'entityPublished') {
          void handleAfterRuleIsPublished();
        } else if (key === 'discardEntity') {
          void discardRule();
        } else if (key === 'formData') {
          const reviewFormData = data.formData.requestReview;

          if (!_isNil(reviewFormData)) {
            setApprovalInfoRule(reviewFormData);
          }
        } else if (key === 'fetchEntity') {
          void handleFetchRule();
        } else {
          setValue(key, data[key]);
        }
      });
    }
  };

  const hasConnError =
    hasConnectorErrCustomAttrSheet.restAPI ||
    hasConnectorErrCustomAttrSheet.dataset;

  const vcComponentId = getValueFromObject(
    plan,
    'plan.versionControl.componentId'
  );

  return (
    <RuleEditorContainer gutter={0}>
      <EditorPanelContainer className="decision-rule-editor-container">
        <RulesContainerStyled className="decision-rule-editor-content">
          <RuleInputHeader
            className="decision-rule-table-info-container"
            gutter={1}
          >
            <CustomInputContainer>
              <Button
                onClick={() => {
                  handleSendEventToGTM({
                    action: 'add',
                    element: 'customAttributes',
                  });
                  openCustomAttributeSheet({ isReadOnly });
                }}
              >
                {hasCustomAttributes ? <MdEdit /> : <FaPlus />}
                {`${hasCustomAttributes ? 'Edit' : 'Add'} Input Attributes`}
              </Button>
              {hasConnError && (
                <Typography fontWeight={700} name="error">
                  {getTooltipText(
                    siteConstants,
                    'integrations',
                    'integrationNotConnected'
                  )}
                </Typography>
              )}

              {!_isNil(assetsInDraftMap) && !_isEmpty(assetsInDraftMap) && (
                <Typography fontWeight={700} name="error">
                  {`${
                    hasConnError ? ', ' : ''
                  }Use datasource published version`}
                </Typography>
              )}
            </CustomInputContainer>
            <HeaderActionsContainer>
              <Typography name="secondarySmall">{`${rowCount} Rows & ${columnCount} Columns`}</Typography>
            </HeaderActionsContainer>
          </RuleInputHeader>
          <RuleNodesContainer scrollStarted={scrollStarted}>
            <Stack gutter={20}>
              <Inline gutter={5} minItemWidth={45} align="center">
                <PadBox padding={10}>
                  <DecisionTableBlock control={control} setValue={setValue} />
                </PadBox>
              </Inline>

              <AdditionalOutputData
                control={control}
                handleSendEventToGTM={handleSendEventToGTM}
                setValue={setValue}
              />

              <DataAddActionDecisionTable
                control={control}
                setValue={setValue}
                handleSendEventToGTM={handleSendEventToGTM}
              />
            </Stack>
          </RuleNodesContainer>

          <PadBox padding={[0, 0, '2rem', '1rem']}>
            <EntityUnderReview entityType="Rule" entityStatus={status} />
          </PadBox>
        </RulesContainerStyled>
      </EditorPanelContainer>

      {activePanel === 'settings' && (
        <DynamicPanelContainer className="decision-rule-trigger-container">
          <StackAsItem
            grow={1}
            gutter={0}
            className="decision-rule-trigger-content"
          >
            <Triggers control={control} setValue={setValue} />
          </StackAsItem>
        </DynamicPanelContainer>
      )}

      {activePanel === 'versionControl' && (
        <DynamicPanelContainer
          id={vcComponentId}
          data-premium-component-id={vcComponentId}
          data-premium-component-trigger={getValueFromObject(
            plan,
            'plan.versionControl.trigger'
          )}
          activePanel={activePanel}
        >
          <VersionControl
            entityInfo={{
              type: ENTITY_ID.rules,
              status,
              name: ruleName ?? '',
              id: ruleId ?? '',
              subType: 'decisionTable',
              accessRole: accessRoleValue,
            }}
            handleEntityUpdate={handleEntityUpdate}
            currentTab={vcListTabIndex}
            modalFormdata={{
              requestReview: approvalInfoRule,
              publishModal: {
                title: approvalInfoRule?.title,
              },
            }}
            updateTabIndex={setVcListTabIndex}
          />
        </DynamicPanelContainer>
      )}

      {activePanel === 'faqMenu' && (
        <DynamicPanelContainer activePanel={activePanel}>
          <FAQMenu />
        </DynamicPanelContainer>
      )}

      {activePanel === 'dependencyUsage' && (
        <DynamicPanelContainer
          id={getValueFromObject(plan, 'plan.dependencyMap.componentId')}
          data-premium-component-id={getValueFromObject(
            plan,
            'plan.dependencyMap.componentId'
          )}
          data-premium-component-trigger={getValueFromObject(
            plan,
            'plan.dependencyMap.trigger'
          )}
          activePanel={activePanel}
        >
          <EntityDependencyUsage
            entityInfo={{
              type: ENTITY_ID.rules,
              status,
              name: ruleName ?? '',
              id: ruleId ?? '',
              subType: 'decisionTable',
              version: versionInfoRule?.currentVersion ?? 'draft',
            }}
          />
        </DynamicPanelContainer>
      )}

      <SidePanelContainer>
        <Panel
          defaultActivePanel={activePanel}
          onPanelItemClick={setActivePanel}
        />
      </SidePanelContainer>
    </RuleEditorContainer>
  );
};
