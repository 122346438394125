import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  DataTypes,
  Dataset,
  ExpandingTextField,
  Sheet,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Typography,
  getDataTypeNected,
  getObjectUnion,
  toasts,
  useCurrentLayer,
  useLayer,
} from 'ui';

import {
  getPropertyIfExists,
  handleSetCheckSumByEntityName,
} from '../../../../../utils/common';
import { stopPropagate } from '../../../../../utils/form';
import { AttributeModel } from '../../../../Rules/models';
import {
  changedNodeIdsAtom,
  isWorkflowReadOnlyAtom,
  isWorkflowTestOnlyAtom,
  versionInfoWorkflowAtom,
  versionMappingWfInfoAtom,
  workflowErrorByNodeAtom,
  workflowIdAtom,
  workflowNodeSavingAtom,
  workflowNodesAtom,
} from '../../../atoms/atoms';
import { useGetRuleById } from '../../../hooks/graphql/useGetRuleById';
import { useGetIdsToBeNotRendered } from '../../../hooks/useGetIdsToBeNotRendered';
import { useTestWorkflowRuleNode } from '../../../hooks/useTestWorkflowRuleNode';
import { RunInLoopType } from '../../../models/models';
import {
  checkIfNameExists,
  formatRuleAttributes,
  getEntityNameById,
  getExecutedValueAndStatus,
  transformRuleNode,
} from '../../../utils/common';
import {
  nodeNameValidationBeforeSave,
  validateRuleParams,
} from '../../../utils/validations';
import {
  WorkflowSheetFormStyled,
  WorkflowSheetTabContentStyled,
} from '../../CommonStyles/CommonStyles.styled';
import { RuleSheetCloseModal } from '../../Modals/RuleSheetCloseModal/RuleSheetCloseModal';
import { RuleMapping } from './RuleMapping';
import { RuleSettings } from './RuleSettings';
import { FooterStyled } from './RuleSheet.styled';
import { RuleTest } from './RuleTest/RuleTest';
import { RuleTestRs } from './RuleTest/RuleTestRs';

type RuleSheetProps = {
  data?: any;
  id?: string;
};

export function RuleSheet({ data: localData, id = '' }: RuleSheetProps) {
  const [isResultDisabled] = useState(false);

  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [isWorkflowTestOnly] = useAtom(isWorkflowTestOnlyAtom);

  const [, setWorkflowErrorByNode] = useAtom(workflowErrorByNodeAtom);
  const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);
  const [workflowNodeSaving, setIsWorkflowNodeSaving] = useAtom(
    workflowNodeSavingAtom
  );

  const [currentStatus, setCurrentStatus] = useState<string>(
    localData.status ?? ''
  );

  const [versionMappingInfo, setVersionMappingInfo] = useAtom(
    versionMappingWfInfoAtom
  );
  const [workflowId] = useAtom(workflowIdAtom);
  const [versionInfo] = useAtom(versionInfoWorkflowAtom);

  const currNodeVersionMapping = versionMappingInfo?.find(
    (currMapping) => currMapping.nodeId === id
  );

  const [counter, setCounter] = useState(0);
  const [currentTab, setCurrentTab] = useState(0);
  const [isSaving, setIsSaving] = useState(false);

  const { close } = useCurrentLayer();

  const { open: openRuleSheetModal } = useLayer(
    <RuleSheetCloseModal onClose={close} />
  );

  const {
    testRuleData,
    ruleData,
    ruleError,
    updatedDataSet,
    isTesting,
    setIsTesting,
    parentNodes,
    directParents,
  } = useTestWorkflowRuleNode({
    id,
    localData,
    type: 'rule',
  });

  const [currentCustomInputs, setCurrentCustomInputs] = useState<
    Record<string, AttributeModel>
  >({});

  const workflowNode = workflowNodes.find((wn) => wn.id === id);

  const [getRuleById] = useGetRuleById();

  const { control, handleSubmit, setValue, watch, setError, clearErrors } =
    useForm<any>({
      defaultValues: {
        attributes: [],
        runInLoop: localData.runInLoop ?? {
          status: false,
          source: '',
          attribute: '',
        },
        settings: {
          timeout: 1000,
          runAction: false,
          actionInSync: false,
          errorContinue: false,
          ...(localData.settings ?? {}),
        },
        name: localData.name,
        selectedRule: null,
      },
    });

  const onSubmit = async (dt: any, test: boolean = false) => {
    clearErrors();
    setIsSaving(true);
    setWorkflowErrorByNode((prev) => ({
      ...prev,
      [id]: undefined,
    }));

    if (test) {
      const isValid = await validateRuleParams(
        dt,
        finalDataSet,
        test ? setError : undefined
      );

      if (!isValid) {
        setIsSaving(false);
        setIsTesting(false);

        return null;
      }
    }

    const isNameValid = nodeNameValidationBeforeSave(dt.name, setError);

    if (!isNameValid) {
      setIsTesting(false);
      setIsSaving(false);

      return;
    }

    if (dt.runInLoop.status as boolean) {
      if (
        // eslint-disable-next-line
        _isNil(dt.runInLoop.source as string | null) ||
        // eslint-disable-next-line
        _isNil(dt.runInLoop.attribute as string | null) ||
        // eslint-disable-next-line
        dt.runInLoop.source === '' ||
        // eslint-disable-next-line
        dt.runInLoop.attribute === ''
      ) {
        setError('runInLoop.value', {
          message: 'Please map a valid list',
        });

        return;
      }
    }

    if (
      (dt.runInLoop.status as boolean) &&
      // eslint-disable-next-line
      !dt.attributes.find((attr: any) => attr.source === 'loop')
    ) {
      setIsTesting(false);
      setIsSaving(false);

      return toasts.error(
        'You must map at least a single value!',
        'value-map-error'
      );
    }

    if (test) {
      setIsTesting(true);
    }

    if (!_isNil(workflowNode) && !_isNil(localData.onWorkflowNodeChange)) {
      const newWorkflowNode = workflowNode;

      if (
        dt.name !== localData.name &&
        typeof localData.updateOnNameChange === 'function'
      ) {
        const doesNameExist = checkIfNameExists(
          workflowNodes,
          dt.name,
          newWorkflowNode
        );

        if (doesNameExist) {
          setError('name', {
            message: 'Duplicate name provided',
          });

          return null;
        }

        localData.updateOnNameChange({
          id,
          name: localData.name,
          newName: dt.name,
        });
      }

      const value = transformRuleNode(dt);
      newWorkflowNode.data.name = value.name;
      newWorkflowNode.data.settings = value.settings;
      newWorkflowNode.data.input = value.attributes;
      newWorkflowNode.data.entityId = value.selectedRule;
      newWorkflowNode.data.executedValue =
        currentStatus === ''
          ? null
          : getExecutedValueAndStatus(ruleData ?? {}).executedValue ??
            workflowNode.data.executedValue ??
            null;

      newWorkflowNode.data.status = currentStatus;

      if (
        !_isNil(localData.runInLoop) &&
        !_isNil(ruleData?.data?.data?.output)
      ) {
        if (
          runInLoop?.status !== localData.runInLoop?.status ||
          runInLoop?.attribute !== localData.runInLoop?.attribute
        ) {
          newWorkflowNode.data.status = '';
          newWorkflowNode.data.executedValue = null;
        }
      }

      newWorkflowNode.data.runInLoop = (dt.runInLoop.status as boolean)
        ? {
            status: dt.runInLoop.status,
            source: dt.runInLoop.source,
            attribute: dt.runInLoop.attribute,
          }
        : { status: false, source: '', attribute: '' };

      localData.onWorkflowNodeChange(newWorkflowNode);

      setVersionMappingInfo((prev) =>
        prev?.map((mappingObj) => {
          if (mappingObj.nodeId === id) {
            return {
              ...mappingObj,
              entityId: selectedRule.value,
            };
          }

          return mappingObj;
        })
      );

      if (counter > 1) {
        setChangedNodeIds([id]);
        setIsWorkflowNodeSaving(true);
      }

      if (!test) {
        close();
      }
    }

    if (test) {
      setCounter(1);
    }

    setIsSaving(false);

    return null;
  };

  const handleGetRuleProps = async (
    id: string = '',
    isSetValue: boolean = false
  ) => {
    if (_isEmpty(id)) {
      return [];
    }

    const version = currNodeVersionMapping?.version ?? '';

    const filters: Record<string, any> = {};

    if (!['live', 'draft'].includes(version)) {
      filters.eq = { version };
    }

    try {
      const data = await getRuleById({
        variables: {
          id,
          live: version !== 'draft',
          filters,
          parentInfo: {
            id: workflowId,
            entity: 'workflow',
            version: versionInfo?.currentVersion ?? 'draft',
          },
        },
        fetchPolicy: 'no-cache',
      });

      if (isSetValue) {
        setCurrentCustomInputs(data.data.getRule?.data[0]?.customInput ?? {});

        const input = { ...JSON.parse(JSON.stringify(localData?.input ?? {})) };

        let isMissingKey = false;

        Object.keys(data.data.getRule?.data[0]?.customInput ?? {}).forEach(
          (key) => {
            if (_isNil(localData?.input?.[key])) {
              isMissingKey = true;

              input[key] = {
                attribute: null,
                dataType:
                  data.data.getRule?.data[0]?.customInput[key].dataType ?? '',
                name: key,
                notSend: false,
                sendNull: false,
                source: null,
                value:
                  data.data.getRule?.data[0]?.customInput[key]?.dataType ===
                  'string'
                    ? ''
                    : null,
              };
            }
          }
        );

        if (isMissingKey && localData.entityId === id) {
          localData.onWorkflowNodeChange({
            ...workflowNode,
            data: { ...localData, input, status: '' },
          });
        }

        setValue(
          'attributes',
          formatRuleAttributes(
            data.data.getRule?.data[0]?.customInput ?? {},
            localData?.input
          )
        );

        setValue(
          'runInLoop',
          localData?.runInLoop ?? {
            status: false,
            source: '',
            attribute: '',
          }
        );
        // eslint-disable-next-line
        if (!!localData?.entityId || !!data.data.getRule?.data[0]?.name) {
          setValue('selectedRule', {
            label: data.data.getRule?.data[0]?.name,
            value: selectedRule?.value ?? localData?.entityId,
            type: data.data.getRule?.data[0]?.type,
          });
        }
      }

      if (!_isNil(data.data.getRule?.data[0]?.checksum) && isSetValue) {
        handleSetCheckSumByEntityName(
          'rule',
          data.data.getRule?.data[0]?.checksum
        );
      }

      return [
        data.data.getRule?.data[0]?.action?.then?.outputData,
        data.data.getRule?.data[0]?.action?.else?.outputData,
        data.data.getRule?.data[0]?.type,
      ];
    } catch (error) {
      // eslint-disable-next-line
      console.log('error', error);
    }

    return [];
  };
  const selectedRule: Record<string, any> = watch('selectedRule');

  useEffect(() => {
    void handleGetRuleProps(selectedRule?.value ?? localData?.entityId, true);
  }, [
    JSON.stringify(selectedRule?.value),
    JSON.stringify(currNodeVersionMapping),
  ]);

  useEffect(() => {
    if (!workflowNodeSaving && isTesting) {
      void handleTestNode();
    }
  }, [workflowNodeSaving, isTesting]);

  const attributes: any[] = watch('attributes');
  const nodeName: string = watch('name');
  const settings: Record<string, any> = watch('settings');

  useEffect(() => {
    const submitTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {
      setCounter((count) => count + 1);
    }, 500);

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [
    JSON.stringify(nodeName),
    JSON.stringify(selectedRule),
    JSON.stringify(attributes),
    JSON.stringify(settings),
  ]);

  const handleSaveData = stopPropagate(
    handleSubmit(async (data) => await onSubmit(data, false))
  );

  const handleSaveDataAndTest = stopPropagate(
    handleSubmit(async (data) => await onSubmit(data, true))
  );

  const handleTestNode = async () => {
    try {
      await testRuleData(attributes, runInLoop);
    } catch (error) {}
    setIsTesting(false);
  };

  const runInLoop: RunInLoopType | undefined = watch('runInLoop');

  useEffect(() => {
    if (!_isNil(ruleData)) {
      if (!_isNil(workflowNode)) {
        const newWorkflowNode = workflowNode;
        const entity = getExecutedValueAndStatus(ruleData);

        newWorkflowNode.data.status = entity.status;

        newWorkflowNode.data.executedValue = entity.executedValue;

        setChangedNodeIds([]);

        setCurrentStatus(entity.status);

        setTimeout(() => {
          localData.onWorkflowNodeChange(workflowNode);
        }, 100);
      }

      setCurrentTab(0);
      setCurrentTab(1);
      setIsTesting(false);
    }
  }, [ruleData]);

  useEffect(() => {
    if (!_isNil(ruleError)) {
      setCurrentTab(0);
      setCurrentTab(1);
      setIsTesting(false);
    }
  }, [ruleError]);

  useEffect(() => {
    if (counter > 1) {
      setCurrentStatus('');
    }
  }, [counter]);

  const finalDataSet: Record<string, Dataset> = useMemo(() => {
    // eslint-disable-next-line
    if (!!runInLoop?.status) {
      let parentData = getPropertyIfExists(
        JSON.parse(
          JSON.stringify(
            parentNodes.find((p) => p.data.name === runInLoop.source)?.data
              ?.executedValue ?? {}
          )
        ),
        runInLoop.attribute
      );

      if (runInLoop.source === 'globalVar') {
        const mappedData = getPropertyIfExists(
          JSON.parse(
            JSON.stringify(
              Object.keys(updatedDataSet?.globalVar?.attributes ?? {}).reduce(
                (acc, curr) => {
                  return {
                    ...acc,
                    [curr]:
                      updatedDataSet.globalVar.attributes[`${curr}`]
                        .executedValue,
                  };
                },
                {}
              )
            )
          ) ?? {},
          runInLoop.attribute
        );

        parentData = mappedData;
      }

      return {
        loop: {
          name: 'Loop',
          attributes: {
            currentItem: {
              dataType: getDataTypeNected(
                getObjectUnion(parentData ?? [])
              ) as DataTypes,
              name: 'currentItem',
              executedValue: getObjectUnion(parentData ?? []),
            },
          },
          id: 'currentItem',
        },
        ...updatedDataSet,
      };
    }

    return updatedDataSet;
  }, [updatedDataSet, JSON.stringify(runInLoop), parentNodes.length]);

  const { idsToNotExpand } = useGetIdsToBeNotRendered({
    directParents,
    dataSet: updatedDataSet,
  });

  const isLoading = isTesting || workflowNodeSaving || isSaving;

  return (
    <Sheet size="small" onClose={counter > 1 ? openRuleSheetModal : close}>
      <WorkflowSheetFormStyled>
        <PadBox padding={0}>
          <Inline stretch="start">
            <Stack as={PadBox} gutter={48} padding={[16, 24]}>
              <Inline stretch="start">
                <Stack gutter={8}>
                  <Inline align="center" gutter="1.6rem" justify="start">
                    <Typography name="heading2">
                      <ExpandingTextField
                        control={control}
                        name="name"
                        disabled={isWorkflowReadOnly}
                      />
                    </Typography>
                  </Inline>
                </Stack>
              </Inline>
            </Stack>
          </Inline>
        </PadBox>

        <Tabs defaultOpen={currentTab} onTabChange={(i) => setCurrentTab(i)}>
          <TabList>
            <Tab>
              <Typography fontWeight={700}>Input Params</Typography>
            </Tab>

            <Tab disabled={isResultDisabled}>
              <Typography
                fontWeight={700}
                name={isResultDisabled ? 'secondarySmall' : 'paragraph'}
              >
                Test Results
              </Typography>
            </Tab>

            <Tab>
              <Typography fontWeight={700}>Settings</Typography>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <WorkflowSheetTabContentStyled className="rule-node-input-container">
                <PadBox padding="2rem" className="rule-node-input-content">
                  <RuleMapping
                    control={control}
                    setValue={setValue}
                    dataSet={finalDataSet}
                    watch={watch}
                    customInputs={currentCustomInputs}
                    localData={localData}
                    idsToNotExpand={idsToNotExpand}
                    nodeId={id}
                  />
                </PadBox>
              </WorkflowSheetTabContentStyled>
            </TabPanel>
            <TabPanel>
              <PadBox padding="2rem">
                {localData.nodeType === 'ruleSetNode' ? (
                  <RuleTestRs
                    parentNodes={parentNodes}
                    watch={watch}
                    mappedValues={attributes}
                    outputValue={
                      !_isNil(ruleData?.data?.data)
                        ? getExecutedValueAndStatus(ruleData).executedValue
                        : localData.executedValue
                    }
                    error={ruleError ?? {}}
                    ruleData={ruleData}
                    isTesting={isTesting}
                    dataSet={finalDataSet}
                  />
                ) : (
                  <RuleTest
                    parentNodes={parentNodes}
                    watch={watch}
                    mappedValues={attributes}
                    outputValue={
                      !_isNil(ruleData?.data?.data)
                        ? getExecutedValueAndStatus(ruleData).executedValue
                        : localData.executedValue
                    }
                    error={ruleError ?? {}}
                    ruleData={ruleData}
                    isTesting={isTesting}
                    dataSet={finalDataSet}
                  />
                )}
              </PadBox>
            </TabPanel>
            <TabPanel>
              <WorkflowSheetTabContentStyled className="rule-node-settings-container">
                <RuleSettings
                  control={control}
                  name="settings"
                  setValue={setValue}
                  ruleType={getEntityNameById(localData.nodeType)}
                />
              </WorkflowSheetTabContentStyled>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <FooterStyled>
          <Inline
            style={{
              padding: '0.8rem',
            }}
            justify="end"
            gutter="1rem"
          >
            <Button
              appearance="filled"
              disabled={isLoading || !isWorkflowTestOnly}
              onClick={handleSaveDataAndTest}
            >
              {isLoading ? (
                <Spinner size="extraSmall" />
              ) : (
                <Inline>Test</Inline>
              )}
            </Button>

            <Button
              appearance="contained"
              onClick={handleSaveData}
              disabled={isWorkflowReadOnly || isLoading}
            >
              {isLoading && !isTesting ? (
                <Spinner size="extraSmall" />
              ) : (
                <Inline>Save</Inline>
              )}
            </Button>
          </Inline>
        </FooterStyled>
      </WorkflowSheetFormStyled>
    </Sheet>
  );
}
