import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import _upperFirst from 'lodash/upperFirst';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  ExpandingTextField,
  Sheet,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Typography,
  useCurrentLayer,
} from 'ui';

import { siteConstantsAtom } from '../../../../../atom';
import { HowToLink } from '../../../../../components/HowToLink/HowToLink';
import { useSendEventToGTM } from '../../../../../hooks/useSendEventToGTM';
import { getTooltipText } from '../../../../../utils/common';
import { authTypes } from '../../../../Rules/components/Triggers/AuthenticationDropDown';
import {
  changedNodeIdsAtom,
  isWorkflowReadOnlyAtom,
  isWorkflowTestOnlyAtom,
  workflowNodesAtom,
} from '../../../atoms/atoms';
import {
  formatCustomAttributesForSheet,
  transformTriggerSheetAttributes,
  transformTriggerSheetAttributesToExValue,
} from '../../../utils/common';
import { validateApiTrigger } from '../../../utils/validations';
import {
  SheetFooterStyled,
  WorkflowSheetFormStyled,
  WorkflowSheetTabContentStyled,
} from '../../CommonStyles/CommonStyles.styled';
import { ApiInputForm } from './ApiInputForm';
import { ApiSettings } from './ApiSettings';
import { ApiTestResult } from './ApiTestResult';

type ApiTriggerSheetProps = {
  data?: any;
  id?: string;
};

export function ApiTriggerSheet({ id = '', data = {} }: ApiTriggerSheetProps) {
  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [isWorkflowTestOnly] = useAtom(isWorkflowTestOnlyAtom);
  const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);

  const [defaultTab, setDefaultTab] = useState(0);
  const [result, setResult] = useState<Record<string, any>>(
    data.executedValue ?? {
      result: 'Please test to view output',
    }
  );

  const { close } = useCurrentLayer();

  const { control, setValue, handleSubmit, setError, watch } = useForm<any>({
    defaultValues: {
      attributes: formatCustomAttributesForSheet(data.input),
      settings: {
        authType: !_isNil(data.settings?.authType)
          ? {
              label: _upperFirst(data.settings?.authType),
              value: data.settings.authType,
            }
          : authTypes[1],
        isEnabled: data.settings?.isEnabled ?? true,
      },
      name: data.name,
    },
  });

  const { sendEventToGTM } = useSendEventToGTM();

  const onSubmit = (dt: any, isTest: boolean = false) => {
    const { isValid } = validateApiTrigger(dt, setError);

    const workflowNode = workflowNodes.find((wn) => wn.id === id);

    if (isValid) {
      if (!_isNil(workflowNode) && !_isNil(data.onWorkflowNodeChange)) {
        const newWorkflowNode = workflowNode;

        if (
          dt.name !== workflowNode.data.name &&
          typeof data.updateOnNameChange === 'function'
        ) {
          data.updateOnNameChange({
            id,
            name: data.name,
            newName: dt.name,
          });
        }

        newWorkflowNode.data.name = dt.name;
        newWorkflowNode.data.settings = {
          isEnabled: dt.settings.isEnabled ?? false,
          authType: dt.settings.authType?.value,
        };

        newWorkflowNode.data.executedValue =
          transformTriggerSheetAttributesToExValue(dt.attributes);

        newWorkflowNode.data.input = transformTriggerSheetAttributes(
          dt.attributes
        );

        if (isTest) {
          sendEventToGTM({
            event: 'workflow',
            source: 'listing',
            element: 'trigger',
            action: 'test_click',
            type: 'apiTrigger',
          });

          setResult(transformTriggerSheetAttributesToExValue(dt.attributes));
          setDefaultTab(1);
        }

        if (!isTest) {
          data.onWorkflowNodeChange(newWorkflowNode);

          setTimeout(() => {
            setChangedNodeIds([id]);
          }, 100);
        }
      }

      if (!isTest) {
        close();
      }
    }
  };

  const submit = handleSubmit((val: any) => onSubmit(val));
  const submitAndTest = handleSubmit((val: any) => onSubmit(val, true));

  return (
    <Sheet size="mediumLarge" onClose={close}>
      <WorkflowSheetFormStyled>
        <PadBox padding="1rem">
          <Inline stretch="start" align="center">
            <Stack as={PadBox} gutter={48} padding={[16, 24]}>
              <div>
                <Inline align="center" gutter="1.6rem" justify="start">
                  <Typography name="heading2">
                    <ExpandingTextField
                      control={control}
                      name="name"
                      disabled={isWorkflowReadOnly}
                    />
                  </Typography>
                </Inline>
                <Typography name="secondarySmall">
                  A trigger is an event that starts your workflow
                </Typography>
              </div>
            </Stack>

            <HowToLink
              link={getTooltipText(
                siteConstants,
                'workflow',
                'inputAttributesSheetHowTo',
                'howToLinks'
              )}
            />
          </Inline>
        </PadBox>

        <Tabs
          defaultOpen={defaultTab}
          onTabChange={(index) => setDefaultTab(index)}
        >
          <TabList>
            <Tab>
              <Typography fontWeight={700}>Input Params</Typography>
            </Tab>

            <Tab>
              <Typography fontWeight={700}>Test Results</Typography>
            </Tab>

            <Tab>
              <Typography fontWeight={700}>Settings</Typography>
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <WorkflowSheetTabContentStyled>
                <PadBox padding="1rem">
                  <ApiInputForm
                    control={control}
                    triggerId=""
                    setValue={setValue}
                  />
                </PadBox>
              </WorkflowSheetTabContentStyled>
            </TabPanel>

            <TabPanel>
              <PadBox padding="2rem">
                <ApiTestResult result={result} />
              </PadBox>
            </TabPanel>

            <TabPanel>
              <PadBox padding="2rem">
                <ApiSettings control={control} watch={watch} />
              </PadBox>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <SheetFooterStyled>
          <Button
            disabled={!isWorkflowTestOnly}
            appearance="neutral"
            type="button"
            onClick={submitAndTest}
          >
            Test
          </Button>
          <Button
            disabled={isWorkflowReadOnly}
            appearance="contained"
            type="button"
            onClick={submit}
          >
            Save
          </Button>
        </SheetFooterStyled>
      </WorkflowSheetFormStyled>
    </Sheet>
  );
}
