import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import type { UseFormSetError } from 'react-hook-form';
import { Attributes, Dataset, getDataTypeNected, toasts } from 'ui';

import {
  capitalizeHeaderKey,
  getPropertyIfExists,
  isArrayAsInputValid,
} from '../../../utils/common';
import {
  ACTION_KEY_REGEX,
  DATE_FORMAT_REGEX,
  DATE_TIME_REGEX,
  TOKEN_REGEX,
} from '../../../utils/regex';
import { KeyValuePairList } from '../../Integrations/types';
import { DuplicateKeyIndexes } from '../../Rules/types';
import {
  getDefaultValueByDataType,
  validateSimpleRuleBeforeSaving,
  validateTokensBasedOnEditor,
} from '../../Rules/utils/common';
import type {
  WorkflowEdgeType,
  WorkflowNodeType,
} from '../hooks/useOpenWorkflow';
import {
  DelayNodeForm,
  SetVariableNodeForm,
  WorkflowAttribute,
  WorkflowSettings,
} from '../models/models';
import {
  convertCurrentNodeAttributesToDataset,
  getRuleNodeFromPath,
} from './common';

export const validateApiTrigger = (
  data: any,
  setError: UseFormSetError<any>
) => {
  let isValid = true;
  const duplicateKeys: Record<string, number[]> = {};

  data.attributes.forEach((d: any, i: number) => {
    if (!_isNil(duplicateKeys[d.name])) {
      duplicateKeys[d.name].push(i);
    } else {
      duplicateKeys[d.name] = [i];
    }

    if (
      !['boolean', 'numeric', 'list', 'string'].includes(
        d.selectedType?.value
      ) &&
      (_isEmpty(d.executedValue) || _isNil(d.executedValue))
    ) {
      isValid = false;

      setError(`attributes.${i}.executedValue`, {
        message: 'Value cannot be empty',
      });
    } else if (
      d.selectedType?.value === 'numeric' &&
      (_isNil(d.executedValue) || d.executedValue === '')
    ) {
      isValid = false;
      setError(`attributes.${i}.executedValue`, {
        message: 'Value cannot be empty',
      });
    } else if (
      (d.selectedType?.value === 'list' &&
        (_isNil(d.executedValue) || d.executedValue === '')) ||
      (d.selectedType?.value === 'list' &&
        !isArrayAsInputValid(d.executedValue))
    ) {
      isValid = false;
      setError(`attributes.${i}.executedValue`, {
        message: 'Value cannot be empty',
      });
    }
  });

  Object.keys(duplicateKeys).forEach((k) => {
    if (duplicateKeys[k].length > 1) {
      duplicateKeys[k].forEach((v) => {
        isValid = false;

        setError(`attributes.${v}.name`, {
          message: 'Duplicate key name',
        });
      });
    }
  });

  return { isValid };
};

const isNumber = (str: string | number) => {
  // Use typeof to check if the input is already a number
  if (typeof str === 'number') {
    return true;
  }

  // Use the unary plus operator to convert the string to a number
  // and then check if it is a valid number using isNaN
  return !isNaN(+str);
};

export const validateRuleParams = async (
  data: any,
  dataset: Record<string, Dataset>,
  setError?: UseFormSetError<any>,
  isRule: boolean = true
) => {
  let isValid = true;

  if (_isNil(setError)) {
    return isValid;
  }

  if (_isNil(data.selectedRule) && isRule) {
    setError('selectedRule', {
      message: 'Please select a rule',
    });

    isValid = false;
  }

  const timeout = parseInt(data.settings.timeout, 10);

  if (timeout > 60) {
    isValid = false;
    setError('settings.timeout', {
      message: 'Cannot be more than 60s',
    });
  } else if (timeout < 1) {
    isValid = false;
    setError('settings.timeout', {
      message: 'Cannot be less than 1s',
    });
  }

  for (let i = 0; i < data.attributes.length; i++) {
    const dt = data.attributes[i];
    const isSource = !_isNil(dt.attribute) && !_isEmpty(dt.attribute);

    if (dt.isNullable === true || dt.isOptional === true) {
      continue;
    }

    if (
      (dt.source === 'systemVar' || dt.sourceType === 'systemVar') &&
      dt.attribute === 'NULL'
    ) {
      break;
    } else if (isSource) {
      const source = dt.source ?? '';
      const attribute = dt.attribute;
      const dataType = dt.dataType;

      const mappedValue = getPropertyIfExists(
        JSON.parse(
          JSON.stringify(
            Object.keys(dataset[source]?.attributes ?? {}).reduce(
              (acc, curr) => {
                return {
                  ...acc,
                  [curr]: dataset[source].attributes[`${curr}`].executedValue,
                };
              },
              {}
            )
          )
        ) ?? {},
        attribute ?? ''
      );

      const typesToInclude = ['date', 'dateTime'].includes(dataType)
        ? ['date', 'dateTime']
        : [dataType];

      if (!typesToInclude.includes(getDataTypeNected(mappedValue))) {
        setError(`attributes.${i}.value`, {
          message: 'Invalid value datatype',
        });

        return false;
      }
    } else if (!isSource) {
      if (dt.dataType === 'unknown') {
        isValid = false;
        toasts.error('Unsupported data type', 'unsupported-datatype');
        break;
      }

      if (
        (_isNil(dt.attribute) || _isEmpty(dt.attribute)) &&
        _isNil(dt.value)
      ) {
        setError(`attributes.${i}.value`, {
          message: 'Value cannot be empty',
        });

        isValid = false;
      } else if (dt.dataType === 'numeric' && !isSource) {
        if (!isNumber(dt.value) || dt.value === '') {
          setError(`attributes.${i}.value`, {
            message: 'Value must be a valid number',
          });

          isValid = false;
        }
      } else if (dt.dataType === 'date' && !isSource) {
        if (!DATE_FORMAT_REGEX.test(dt.value)) {
          setError(`attributes.${i}.value`, {
            message: 'Value must be a valid date',
          });

          isValid = false;
        }
      } else if (dt.dataType === 'dateTime' && !isSource) {
        if (!DATE_TIME_REGEX.test(dt.value)) {
          setError(`attributes.${i}.value`, {
            message: 'Value must be a valid datetime',
          });

          isValid = false;
        }
      } else if (
        dt.dataType === 'json' ||
        (dt.dataType === 'restApi' && !isSource)
      ) {
        const { isValid: isValidJson, message } =
          await validateTokensBasedOnEditor({
            dataset,
            query: dt.value,
            editorType: 'json',
          });

        if (!isValidJson) {
          setError(`attributes.${i}.value`, {
            message,
          });
          isValid = false;
        }
      } else if (dt.dataType === 'list' && !isSource) {
        if (!isArrayAsInputValid(dt.value)) {
          setError(`attributes.${i}.value`, {
            message: 'Value must be a valid list',
          });

          isValid = false;
        }
      }
    }
  }

  return isValid;
};

export function validateWorkflowBeforeSubmit(
  data: Record<string, any>,
  setError: UseFormSetError<any>,
  nodes: WorkflowNodeType[],
  edges: WorkflowEdgeType[]
) {
  let isSaveValid = true;
  let isTestValid = true;

  const errorNodes: Array<Record<string, any>> = [];

  if (_isNil(data.name) || _isEmpty(data.name)) {
    isSaveValid = false;
    isTestValid = false;

    setError('name', {
      message: 'Please enter a valid name',
    });
  }

  nodes.forEach((node) => {
    if (node.type === 'rule') {
      if (!_isNil(node.data.input) && !_isEmpty(node.data.input)) {
        Object.keys(node.data.input).forEach((key) => {
          const currentData = node.data.input[key];

          if (
            _isNil(currentData.value) &&
            currentData.dataType !== 'string' &&
            (_isNil(currentData.source) || currentData.source === '') &&
            // eslint-disable-next-line
            (!currentData.notSend as boolean) &&
            // eslint-disable-next-line
            (!currentData.sendNull as boolean)
          ) {
            // eslint-disable-next-line
            if (!errorNodes.find((o) => o.id === node.id)) {
              isTestValid = false;

              errorNodes.push({
                id: node.id,
                message: 'Please fill the inputs',
              });
            }
          }
        });
      }
    }
  });

  return { isSaveValid, isTestValid, errorNodes };
}

export const validateConnectorAction = (
  data: Record<string, any>,
  setError?: UseFormSetError<any>
) => {
  let isValid = true;

  if (_isNil(setError)) {
    return isValid;
  }

  if (_isNil(data.integration)) {
    setError('integration', {
      message: 'Please select a connector',
    });

    isValid = false;
  }

  const timeout = parseInt(data.settings.timeout, 10);

  if (timeout > 60 || timeout < 1) {
    if (typeof setError === 'function') {
      setError('settings.timeout', {
        message: 'Timeout must be between 1s and 60s',
      });

      isValid = false;
      toasts.error('Timeout must be between 1s and 60s', 'timeout-issue');
    }
  }

  return isValid;
};

export function nodeNameValidationBeforeSave(
  name: string,
  setError: UseFormSetError<any>
) {
  let isValid = true;

  if (name.length === 0) {
    setError(`name`, {
      message: 'Name cannot be empty',
    });
    isValid = false;
  } else if (name.includes(' ')) {
    setError(`name`, {
      message: 'Name cannot contain spaces',
    });
    isValid = false;
  }

  return isValid;
}

export const uniqueKeyNameValidationForAttributes = (
  attributes: WorkflowAttribute[],
  setError: UseFormSetError<any>
) => {
  let isValidKeys = true;

  const attrkeyNameObj: Record<string, number> = {};

  attributes.forEach((attribute, i) => {
    if (attribute.keyName in attrkeyNameObj) {
      isValidKeys = false;

      setError(`attributes.${i}.keyName`, {
        message: 'keyname should be unique',
      });

      setError(`attributes.${attrkeyNameObj[attribute.keyName]}.keyName`, {
        message: 'keyname should be unique',
      });
    }
    attrkeyNameObj[attribute.keyName] = i;
  });

  return isValidKeys;
};

export function validateDelayNode(
  node: DelayNodeForm,
  setError: UseFormSetError<DelayNodeForm>
) {
  let isValid = true;

  if (!_isNil(node.settings?.timeout)) {
    if (+node.settings?.timeout > 1500) {
      setError('settings.timeout', {
        message: 'Timeout cannot be greater than 1500 seconds',
      });

      isValid = false;
      toasts.error('Timeout must be between 1s and 1500s', 'timeout-issue');
    } else if (+node.settings?.timeout < 1) {
      setError('settings.timeout', {
        message: 'Timeout cannot be less than 1s',
      });

      toasts.error('Timeout must be between 1s and 1500s', 'timeout-issue');
      isValid = false;
    }
  }

  if (node.action.value === 'immed') {
    return isValid;
  } else if (node.action.value === 'AST') {
    if (_isNil(node.dateTime) || node.dateTime === '') {
      setError('dateTime', {
        message: 'Please enter a valid date',
      });

      isValid = false;
    }
  } else if (node.action.value === 'ATI') {
    if (_isNil(node.unit) || node.unit === '') {
      setError('unit', {
        message: 'Please select a valid unit',
      });

      isValid = false;
    }

    if (_isNil(node.amount)) {
      setError('amount', {
        message: 'Please enter a valid amount',
      });

      isValid = false;
    }
  }

  return isValid;
}

export const validateSetVariableOrResponseNode = async (
  node: SetVariableNodeForm,
  dataset: Record<string, Dataset>,
  setError: UseFormSetError<SetVariableNodeForm>
) => {
  let isValidNode = true;

  for (let i = 0; i < node.attributes.length; i++) {
    const dt = node.attributes[i];
    const isSource = !_isNil(dt.attribute) && !_isEmpty(dt.attribute);

    const currNodeAtrrDataset = convertCurrentNodeAttributesToDataset(
      node.attributes.slice(0, i),
      dataset
    );

    const updatedDataset = {
      ...dataset,
      ...currNodeAtrrDataset,
    };

    if (dt.isNullable === true || dt.isOptional === true) {
      continue;
    }

    if (dt.source === 'systemVar' && dt.attribute === 'NULL') {
      break;
    } else if (isSource) {
      const source = dt.source ?? '';
      const attribute = dt.attribute;
      const dataType = dt.dataType;

      const mappedValue = getPropertyIfExists(
        JSON.parse(
          JSON.stringify(
            Object.keys(updatedDataset[source]?.attributes ?? {}).reduce(
              (acc, curr) => {
                return {
                  ...acc,
                  [curr]:
                    updatedDataset[source].attributes[`${curr}`].executedValue,
                };
              },
              {}
            )
          )
        ) ?? {},
        attribute ?? ''
      );

      const typesToInclude = ['date', 'dateTime'].includes(dataType)
        ? ['date', 'dateTime']
        : [dataType, 'generic'];

      const valDatatype = getDataTypeNected(mappedValue);

      if (!typesToInclude.includes(valDatatype) && dataType !== 'string') {
        setError(`attributes.${i}.value`, {
          message: 'Invalid value datatype',
        });

        isValidNode = false;
      }
    } else if (!isSource) {
      if (
        (_isNil(dt.attribute) || _isEmpty(dt.attribute)) &&
        _isNil(dt.value)
      ) {
        setError(`attributes.${i}.value`, {
          message: 'Value cannot be empty',
        });

        isValidNode = false;
      } else if (dt.dataType === 'numeric' && !isSource) {
        if (!isNumber(dt.value) || dt.value === '') {
          setError(`attributes.${i}.value`, {
            message: 'Value must be a valid number',
          });

          isValidNode = false;
        }
      } else if (dt.dataType === 'date' && !isSource) {
        if (!DATE_FORMAT_REGEX.test(dt.value)) {
          setError(`attributes.${i}.value`, {
            message: 'Value must be a valid date',
          });

          isValidNode = false;
        }
      } else if (dt.dataType === 'dateTime' && !isSource) {
        if (!DATE_TIME_REGEX.test(dt.value)) {
          setError(`attributes.${i}.value`, {
            message: 'Value must be a valid datetime',
          });

          isValidNode = false;
        }
      } else if (
        dt.dataType === 'json' ||
        dt.dataType === 'jsFormula' ||
        dt.dataType === 'excelFormula' ||
        (dt.dataType === 'restApi' && !isSource)
      ) {
        const { isValid: isValidJson, message } =
          await validateTokensBasedOnEditor({
            dataset: updatedDataset,
            query: dt.value,
            editorType: dt.dataType,
          });

        if (!isValidJson) {
          setError(`attributes.${i}.value`, {
            message,
          });
          isValidNode = false;
        }
      } else if (dt.dataType === 'list' && !isSource) {
        if (!isArrayAsInputValid(dt.value)) {
          setError(`attributes.${i}.value`, {
            message: 'Value must be a valid list',
          });

          isValidNode = false;
        }
      } else if (dt.dataType === 'json' && !isSource) {
        if (_isNil(dt.value) || dt.value === '') {
          setError(`attributes.${i}.value`, {
            message: 'Value must be a valid json',
          });

          isValidNode = false;
        }
      } else if (dt.dataType === 'boolean') {
        if (!['true', 'false', true, false].includes(dt.value)) {
          setError(`attributes.${i}.value`, {
            message: 'Value must be a valid boolean',
          });

          isValidNode = false;
        }
      }
    }
  }

  if (!_isNil(node.settings?.timeout)) {
    if (+node.settings?.timeout > 60) {
      setError('settings.timeout', {
        message: 'Timeout cannot be greater than 60s',
      });
      toasts.error('Timeout must be between 1s and 60s', 'timeout-issue');

      isValidNode = false;
    } else if (+node.settings?.timeout < 1) {
      setError('settings.timeout', {
        message: 'Timeout cannot be less than 1s',
      });
      toasts.error('Timeout must be between 1s and 60s', 'timeout-issue');

      isValidNode = false;
    }
  }

  return isValidNode;
};

export function validateCodeNode(node: any, setError: UseFormSetError<any>) {
  let isValid = true;

  if (!_isNil(node.settings?.timeout)) {
    if (+node.settings?.timeout > 60) {
      setError('settings.timeout', {
        message: 'Timeout cannot be greater than 60s',
      });
      toasts.error('Timeout must be between 1s and 60s', 'timeout-issue');

      isValid = false;
    } else if (+node.settings?.timeout < 1) {
      setError('settings.timeout', {
        message: 'Timeout cannot be less than 1s',
      });
      toasts.error('Timeout must be between 1s and 60s', 'timeout-issue');

      isValid = false;
    }
  }

  return isValid;
}

export const validateEmptyCheckNode = (
  attributes: Record<string, WorkflowAttribute>
) => {
  let isValid = true;

  Object.keys(attributes).forEach((key) => {
    const datatype = attributes[key].dataType;
    const value = attributes[key].value;

    const isAttribute =
      !_isNil(attributes[key].attribute) && attributes[key].attribute !== '';

    if (
      !isAttribute &&
      !['list', 'string'].includes(datatype) &&
      (_isNil(value) || value === '')
    ) {
      isValid = false;
    }
  });

  if (!isValid) {
    toasts.error('Node value cannot be empty', 'empty-node');
  }

  return isValid;
};

export const validateRestApiNode = async (
  node: any,
  setError: UseFormSetError<any>,
  dataset: Record<string, Dataset>
) => {
  let isValid = true;

  if (_isNil(node.integration)) {
    setError('integration', {
      message: 'Please select a connector',
    });

    isValid = false;
  }

  const inputKeys = Object.keys(node.input);

  for (let i = 0; i < inputKeys.length; i++) {
    const key = inputKeys[i];
    const value = node.input[key]?.value;

    if (key === 'headers') {
      const duplicateKeyNames: DuplicateKeyIndexes = {};

      for (let index = 0; index < value.length; index++) {
        const header: KeyValuePairList = value[index];

        if (_isNil(duplicateKeyNames[header.key])) {
          duplicateKeyNames[header.key] = [index];
        } else {
          isValid = false;
          duplicateKeyNames[header.key] = [
            ...duplicateKeyNames[header.key],
            index,
          ];
        }

        if (_isEmpty(header.key) || !ACTION_KEY_REGEX.test(header.key)) {
          isValid = false;

          setError(`input.headers.value.${index}.key`, {
            message: `Key name must be proper`,
          });
        }

        if (_isNil(header.value) || _isEmpty(header.value)) {
          isValid = false;

          setError(`input.headers.value.${index}.value`, {
            message: `Value must not be empty`,
          });
        }

        const matchedExpressions = (header.value as string).match(TOKEN_REGEX);

        matchedExpressions?.forEach((expression) => {
          const splitExpression = expression.split('.');

          if (
            splitExpression[0] === 'headers' &&
            splitExpression[splitExpression.length - 1] !==
              capitalizeHeaderKey(header.key)
          ) {
            isValid = false;

            setError(`input.headers.value.${index}.value`, {
              message: `Incorrect header token used`,
            });
          }
        });

        const { isValid: isValidHeaderValue, message } =
          await validateTokensBasedOnEditor({
            dataset,
            query: header.value,
            editorType: '',
          });

        if (!isValidHeaderValue) {
          setError(`input.headers.value.${index}.value`, {
            message,
          });
        }
      }

      _forEach(duplicateKeyNames, (val, key) => {
        if (val.length > 1) {
          val.forEach((i) => {
            setError(`input.headers.value.${i}.key`, {
              message: 'Key name must be unique',
            });
          });
        }
      });
    } else if (key === 'queryParams') {
      const duplicateKeyNames: DuplicateKeyIndexes = {};

      for (let index = 0; index < value.length; index++) {
        const queryParam: KeyValuePairList = value[index];

        if (_isNil(duplicateKeyNames[queryParam.key])) {
          duplicateKeyNames[queryParam.key] = [index];
        } else {
          duplicateKeyNames[queryParam.key] = [
            ...duplicateKeyNames[queryParam.key],
            index,
          ];
        }

        if (
          _isEmpty(queryParam.key) ||
          !ACTION_KEY_REGEX.test(queryParam.key)
        ) {
          isValid = false;

          setError(`input.queryParams.value.${index}.key`, {
            message: `Key name must be proper`,
          });
        }

        if (_isNil(queryParam.value) || _isEmpty(queryParam.value)) {
          isValid = false;

          setError(`input.queryParams.value.${index}.value`, {
            message: `Value must not be empty`,
          });
        }

        const { isValid: isValidQueryParamValue, message } =
          await validateTokensBasedOnEditor({
            dataset,
            query: queryParam.value,
            editorType: '',
          });

        if (!isValidQueryParamValue) {
          setError(`input.queryParams.value.${index}.value`, {
            message,
          });
        }
      }

      _forEach(duplicateKeyNames, (val, key) => {
        if (val.length > 1) {
          isValid = false;
          val.forEach((i) => {
            setError(`input.queryParams.value.${i}.key`, {
              message: 'Key name must be unique',
            });
          });
        }
      });
    } else if (key === 'method') {
      if (_isNil(value)) {
        isValid = false;

        setError(`input.method.value`, {
          message: `Please select a method`,
        });
      }
    }
  }

  if (!_isNil(node.settings?.timeout)) {
    if (+node.settings?.timeout > 120) {
      setError('settings.timeout', {
        message: 'Timeout cannot be greater than 120s',
      });
      toasts.error('Timeout must be between 1s and 120s', 'timeout-issue');

      isValid = false;
    } else if (+node.settings?.timeout < 1) {
      setError('settings.timeout', {
        message: 'Timeout cannot be less than 1s',
      });
      toasts.error('Timeout must be between 1s and 120s', 'timeout-issue');

      isValid = false;
    }
  }

  return isValid;
};

export const validateGSheetNode = async (
  node: any,
  setError: UseFormSetError<any>,
  dataset: Record<string, Dataset>
) => {
  let isValid = true;

  if (_isNil(node.entityId)) {
    setError('integration', {
      message: 'Please select a connector',
    });

    isValid = false;
  }

  try {
    const rowLimit = parseInt(node.settings.rowLimit);

    if (!Number.isNaN(rowLimit) && (rowLimit > 10000 || rowLimit < 1)) {
      setError('settings.rowLimit', {
        message: 'Row limit must be between 1 and 10000',
      });

      isValid = false;
    } else if (Number.isNaN(rowLimit)) {
      setError('settings.rowLimit', {
        message: 'Please enter a valid number',
      });

      isValid = false;
    }
  } catch {}

  const isInsert = node.input?.actionMethod?.value === 'insert';
  const isLookup = node.input?.actionMethod?.value === 'lookup';

  const inputKeys = Object.keys(node.input)
    .filter((k) =>
      [
        'file',
        'sheet',
        'mappingMethod',
        'hasHeader',
        'actionMethod',
        'columnMatch',
        'policy',
      ].includes(k)
    )
    .filter((k) => {
      if (isInsert && !isLookup && ['policy', 'columnMatch'].includes(k)) {
        return false;
      } else if (
        isLookup &&
        ['policy', 'columnMatch', 'filterList', 'mappingMethod'].includes(k)
      ) {
        return false;
      }

      return true;
    });

  for (let i = 0; i < inputKeys.length; i++) {
    const key = inputKeys[i];
    const value = node.input[key]?.value;

    if (_isNil(value)) {
      setError(key, {
        message: 'Field is required',
      });

      isValid = false;
    }
  }

  // eslint-disable-next-line
  if (!!node.input?.filterList?.source) {
    const mappedValue = getPropertyIfExists(
      JSON.parse(
        JSON.stringify(
          Object.keys(
            dataset[node.input?.filterList?.source]?.attributes ?? {}
          ).reduce((acc, curr) => {
            return {
              ...acc,
              [curr]:
                dataset[node.input?.filterList?.source].attributes[`${curr}`]
                  .executedValue,
            };
          }, {})
        )
      ) ?? {},
      node.input?.filterList?.attribute ?? ''
    );

    if (_isUndefined(mappedValue)) {
      setError(`filterList.value`, {
        message: 'Invalid token mapped',
      });

      isValid = false;
    }
  } else if (
    !_isNil(node.input?.filterList?.value) &&
    typeof node.input?.filterList?.value === 'string'
  ) {
    const { isValid: isValidList, message } = await validateTokensBasedOnEditor(
      {
        dataset,
        query: node.input?.filterList?.value,
        editorType: 'json',
      }
    );

    if (!isValidList) {
      setError(`filterList.value`, {
        message: message ?? 'Invalid value used',
      });

      isValid = false;
    }
  }

  if (node.input?.actionMethod?.value === 'lookup') {
    const groupNode = getRuleNodeFromPath(node?.conditions?.nodes);

    const columnNameDataSet: Record<string, Dataset> = {
      columnName: {
        name: 'Column Name',
        id: 'columnName',
        attributes: {},
      },
    };

    if (node.input?.hasHeader?.value === 'yes') {
      columnNameDataSet.columnName.attributes = Object.keys(
        node.input?.columnData?.value
      ).reduce<Record<string, Attributes>>((acc, curr) => {
        return {
          ...acc,
          [node.input?.columnData?.value[curr].headerColumnName]: {
            name: node.input?.columnData?.value[curr].headerColumnName,
            dataType: node.input?.columnData?.value[curr].dataType,
            executedValue: getDefaultValueByDataType(
              node.input?.columnData?.value[curr].dataType
            ),
          },
        };
      }, {});
    } else if (node.input?.hasHeader?.value === 'no') {
      columnNameDataSet.columnName.attributes = Object.keys(
        node.input?.columnData?.value
      ).reduce<Record<string, Attributes>>((acc, curr) => {
        return {
          ...acc,
          [curr]: {
            name: curr,
            dataType: node.input?.columnData?.value[curr].dataType,
            executedValue: getDefaultValueByDataType(
              node.input?.columnData?.value[curr].dataType
            ),
          },
        };
      }, {});
    }

    columnNameDataSet.columnName.attributes = {
      ...columnNameDataSet.columnName.attributes,
      ROW_NUMBER: {
        dataType: 'numeric',
        name: 'ROW_NUMBER',
        executedValue: 0,
      },
    };

    const errorByNodeId = validateSimpleRuleBeforeSaving(
      node?.conditions?.nodes,
      groupNode.key,
      { ...dataset, ...columnNameDataSet }
    );

    const keysOfError = Object.keys(errorByNodeId);

    keysOfError.forEach((key) => {
      if (node.conditions.nodes[key].nodeType === 'constant') {
        setError(`paths.0.${key}.value`, {
          message: errorByNodeId[key].message ?? 'Incorrect value used',
        });
      } else {
        setError(`paths.0.${key}`, {
          message: errorByNodeId[key].message ?? 'Incorrect value used',
        });
        setError(`paths.0.${key}.value`, {
          message: errorByNodeId[key].message ?? 'Incorrect value used',
        });
      }
    });

    if (keysOfError.length > 0) {
      return false;
    }
  }

  return isValid;
};

export const validateSwitchNode = (
  data: any,
  dataset: Record<string, Dataset>,
  setError: UseFormSetError<any>
) => {
  let isValid = true;

  const nameMap: Record<string, number[]> = {};

  data.paths.forEach((path: any, index: number) => {
    const groupNode = getRuleNodeFromPath(path);

    const errorByNodeId = validateSimpleRuleBeforeSaving(
      path,
      groupNode.key,
      dataset
    );

    if (groupNode.value.name.length < 1 && groupNode.value.name.length > 20) {
      setError(`paths.${index}.${groupNode.key}.name`, {
        message: 'Pathname must be less than or equal to 20 characters',
      });

      isValid = false;
    }

    if (
      !_isNil(nameMap[groupNode.value.name]) &&
      nameMap[groupNode.value.name].length > 0
    ) {
      setError(`paths.${index}.${groupNode.key}.name`, {
        message: 'Duplicate pathname',
      });

      isValid = false;
    } else {
      nameMap[groupNode.value.name] = [index];
    }

    if (groupNode.value.name === 'default') {
      setError(`paths.${index}.${groupNode.key}.name`, {
        message: 'Pathname cannot be default',
      });

      isValid = false;
    }

    Object.keys(errorByNodeId).forEach((key) => {
      if (path[key].nodeType === 'constant') {
        setError(`paths.${index}.${key}.value`, {
          message: errorByNodeId[key].message ?? 'Incorrect value used',
        });
      } else {
        setError(`paths.${index}.${key}`, {
          message: errorByNodeId[key].message ?? 'Incorrect value used',
        });
        setError(`paths.${index}.${key}.value`, {
          message: errorByNodeId[key].message ?? 'Incorrect value used',
        });
      }

      isValid = false;
    });
  });

  return isValid;
};

export const validateWorkflowSettings = (
  settings: WorkflowSettings,
  setError: UseFormSetError<any>
) => {
  const isValid = true;

  return isValid;
};
