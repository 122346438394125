import { PadBox } from '@bedrock-layout/padbox';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import type { UseFormSetValue } from 'react-hook-form';
import { type Attributes, TextButton, useLayer } from 'ui';

import { siteConstantsAtom } from '../../../atom';
import { HowToLink } from '../../../components/HowToLink/HowToLink';
import {
  CustomAttributeSheet,
  customAttributesAtom,
} from '../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import { generateUid, getTooltipText } from '../../../utils/common';
import type { Role } from '../../Workspace/component/types';
import { dataSetParamsAtom } from '../components/CreateRuleSheet/CreateRuleSheet';
import {
  simpleRuleNodesAtom,
  simpleStartNodeAtom,
  tokensAtom,
} from '../components/SimpleRule';
import type {
  ResultAddDataModel,
  SimpleRuleNodesModel,
  StagingConfigModel,
} from '../components/SimpleRule/models';
import {
  dataSetFieldsByIdAtom,
  isRuleReadOnlyAtom,
  selectedDataSetAtom,
} from '../index';
import type { AttributeModel } from '../models';
import type { ResultAction } from '../types';
import { getDefaultValueByDataTypeV2, getUpdatedTokens } from '../utils/common';
import { useGetVariables } from './graphql/useGetVariables';
import type { VariablesResult } from './graphql/useGetVariables';
import { useInitializeDataSets } from './useInitializeDataSets';

type UseInitializeSimpleRuleProps = {
  nodesStartId: string;
  nodeList: Record<string, SimpleRuleNodesModel>;
  customDataParams: Record<string, AttributeModel>;
  thenDataParams: ResultAddDataModel[];
  elseDataParams: ResultAddDataModel[];
  setValue: UseFormSetValue<any>;
  updatedRuleName: string;
  updatedRuleDescription: string;
  stagingConfig?: StagingConfigModel;
  productionConfig?: StagingConfigModel;
  selectedDataSets?: string[];
  thenActionResponse?: ResultAction[];
  elseActionResponse?: ResultAction[];
  createdAt?: string;
  publishedAt?: string;
  status?: string;
  accessRole?: Role;
};

export function useInitializeSimpleRule({
  nodesStartId,
  nodeList,
  customDataParams,
  thenDataParams,
  elseDataParams,
  setValue,
  stagingConfig,
  productionConfig,
  updatedRuleName,
  updatedRuleDescription,
  selectedDataSets,
  thenActionResponse,
  elseActionResponse,
  createdAt,
  publishedAt,
  status,
  accessRole,
}: UseInitializeSimpleRuleProps) {
  const [dataSetParams, setDatasetParams] = useAtom(dataSetParamsAtom);
  const [startNodeId, setStartNodeId] = useAtom(simpleStartNodeAtom);
  const [ruleList, setRuleList] = useAtom(simpleRuleNodesAtom);
  const [customAttributes, setCustomAttributes] = useAtom(customAttributesAtom);
  const [, setTokens] = useAtom(tokensAtom);
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [dataSetSelected, setSelectedDataSets] = useAtom(selectedDataSetAtom);
  const [dataSetFieldsById] = useAtom(dataSetFieldsByIdAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);

  const [getVariables, { loading }] = useGetVariables();

  const { openWithProps: openCustomAttributeSheet } = useLayer(
    <CustomAttributeSheet isReadOnly={isRuleReadOnly} />
  );

  const setGlobalCustomToDataSet = (data?: VariablesResult) => {
    if (_isNil(data)) {
      return;
    }

    if (!_isNil(data.getGlobalVars) && data.getGlobalVars.data.length > 0) {
      setDatasetParams((prev) => ({
        ...prev,
        [data.getGlobalVars.data[0].category]: {
          name: 'Global Attributes',
          id: generateUid('global_'),
          order: 2,
          tooltip: (
            <span>
              {getTooltipText(siteConstants, 'rules', 'condGlobalAttributes')}{' '}
              <HowToLink
                variant="link"
                link={getTooltipText(
                  siteConstants,
                  'rules',
                  'condGlobalAttributeHowTo',
                  'howToLinks'
                )}
              />
            </span>
          ),
          attributes: data.getGlobalVars.data.reduce<
            Record<string, Attributes>
          >((acc, curr) => {
            return {
              ...acc,
              [curr.name]: {
                name: curr.name,
                dataType: curr.dataType,
                executedValue:
                  curr.dataType === 'json' && typeof curr.value === 'string'
                    ? JSON.parse(curr.value)
                    : curr.value,
              },
            };
          }, {}),
        },
      }));
    }

    if (!_isNil(data.getSystemVars) && data.getSystemVars.data.length > 0) {
      setDatasetParams((prev) => ({
        ...prev,
        [data.getSystemVars.data[0].category]: {
          name: 'System Attributes',
          id: generateUid('system_'),
          order: 5,
          tooltip: (
            <span>
              {getTooltipText(siteConstants, 'rules', 'condSystemAttributes')}{' '}
              <HowToLink
                variant="link"
                link={getTooltipText(
                  siteConstants,
                  'rules',
                  'condSystemAttributeHowTo',
                  'howToLinks'
                )}
              />
            </span>
          ),
          attributes: data.getSystemVars.data.reduce<
            Record<string, Attributes>
          >((acc, curr) => {
            return {
              ...acc,
              [curr.name]: {
                name: curr.name,
                dataType: curr.dataType,
                executedValue: getDefaultValueByDataTypeV2(curr.dataType),
              },
            };
          }, {}),
        },
      }));
    }
  };

  useEffect(() => {
    const getGlobalSystemVars = async () => {
      try {
        const { data } = await getVariables();

        setGlobalCustomToDataSet(data);
      } catch {}
    };

    void getGlobalSystemVars();
  }, [siteConstants]);

  useEffect(() => {
    setStartNodeId(nodesStartId);
  }, [nodesStartId]);

  useEffect(() => {
    setRuleList(structuredClone(nodeList));
  }, [nodeList]);

  useEffect(() => {
    setValue('thenDataParams', thenDataParams);
  }, [thenDataParams]);

  useEffect(() => {
    setValue('elseDataParams', elseDataParams);
  }, [elseDataParams]);

  useEffect(() => {
    setValue('ruleName', updatedRuleName);
  }, [updatedRuleName]);

  useEffect(() => {
    setValue('ruleDescription', updatedRuleDescription);
  }, [updatedRuleDescription]);

  useEffect(() => {
    setValue('thenActionParams', thenActionResponse);
  }, [thenActionResponse]);

  useEffect(() => {
    setValue('elseActionParams', elseActionResponse);
  }, [elseActionResponse]);

  useEffect(() => {
    setValue('createdAt', createdAt);
  }, [createdAt]);

  useEffect(() => {
    setValue('publishedAt', publishedAt);
  }, [publishedAt]);

  useEffect(() => {
    setValue('status', status);
  }, [status]);

  useEffect(() => {
    setValue('accessRole', accessRole);
  }, [accessRole]);

  useEffect(() => {
    if (Object.keys(customDataParams).length > 0) {
      setCustomAttributes(customDataParams);
    }
  }, [customDataParams]);

  useEffect(() => {
    if (!_isNil(customAttributes)) {
      setDatasetParams((prev) => ({
        ...prev,
        customInput: {
          name: 'Input Attributes',
          id: generateUid('constant_'),
          order: 1,
          tooltip: (
            <span>
              {getTooltipText(siteConstants, 'rules', 'condInputAttributes')}{' '}
              <HowToLink
                variant="link"
                link={getTooltipText(
                  siteConstants,
                  'rules',
                  'condInputAttributeHowTo',
                  'howToLinks'
                )}
              />
            </span>
          ),
          footer:
            Object.keys(customAttributes).length === 0 ? (
              <PadBox padding={['0.4rem', '0.4rem', '0.4rem', '2rem']}>
                <TextButton
                  onClick={() =>
                    openCustomAttributeSheet({ isReadOnly: isRuleReadOnly })
                  }
                >
                  Add Input Attribute
                </TextButton>
              </PadBox>
            ) : null,
          attributes: Object.keys(customAttributes)
            .filter((key) => key !== 'CI0')
            .reduce((acc, key) => {
              return {
                ...acc,
                [key]: {
                  name: customAttributes[key].name,
                  dataType: customAttributes[key].dataType?.value,
                  sampleValue: customAttributes[key].sampleValue,
                  executedValue: customAttributes[key].executedValue,
                  config: customAttributes[key].config,
                },
              };
            }, {}),
        },
      }));
    }
  }, [customAttributes, siteConstants]);

  useEffect(() => {
    setDatasetParams((prev) => ({
      ...prev,
      custom: {
        name: 'Custom Functions',
        id: generateUid('param_'),
        order: 4,
        tooltip: (
          <span>
            {getTooltipText(siteConstants, 'rules', 'customFunctionRule')}{' '}
            <HowToLink
              variant="link"
              link={getTooltipText(
                siteConstants,
                'rules',
                'condCustomFunction',
                'howToLinks'
              )}
            />
          </span>
        ),
        attributes: {
          sql: {
            name: 'JS Code',
            dataType: 'jsCondition',
          },
          excel: {
            name: 'Formula',
            dataType: 'excelCondition',
          },
        },
      },
    }));
  }, [siteConstants]);

  useEffect(() => {
    if (!_isNil(productionConfig)) {
      setValue('productionConfig', productionConfig);
    }
  }, [productionConfig]);

  useEffect(() => {
    setTokens(getUpdatedTokens(dataSetParams));
  }, [dataSetParams]);

  useEffect(() => {
    if (!_isNil(selectedDataSets) && selectedDataSets.length > 0) {
      setSelectedDataSets(selectedDataSets);
    }
  }, [selectedDataSets]);

  useInitializeDataSets({ dataSetSelected, dataSetFieldsById });

  return {
    startNodeId,
    ruleList,
    loading,
  };
}
