import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import debounce from 'lodash/debounce';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TbDownload } from 'react-icons/tb';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  PopoverMethods,
  Spinner,
  Typography,
  toasts,
  useLayer,
} from 'ui';

import { subscriptionPlanAtom } from '../../atom';
import { Header } from '../../components/Header';
import { NewTable } from '../../components/table/NewTable';
import { useAxiosPrivate } from '../../hooks';
import { useSendEventToGTM } from '../../hooks/useSendEventToGTM';
import type { SubscriptionPlanType } from '../../types';
import { LISTING_PAGE_SIZE } from '../../utils/constant';
import { BannerBox } from '../BannerBox';
import { ListingBlock } from '../ListingBlocks';
import { ImportEntityModal } from '../Modals/ImportEntityModal/ImportEntityModal';
import { permissionObj } from '../PermissionComponent/constant';
import { useCheckPermissions } from '../PermissionComponent/hooks/useCheckPermissions';
import { RenderFilters } from './components/RenderFilters';
import { getTableColumns } from './components/getTableColums';
import { renderAppliedFilters } from './components/renderAppliedFilters';
import { RuleTemplatePopup } from './gettingStarted/components/rulesTemplate';
import {
  entityTitles,
  gettingStartedContentBlock,
} from './gettingStarted/content';
import {
  FilterContainer,
  ListingContainer,
  NoDataContainer,
} from './listing.styled';
import type {
  AppliedFiltersProps,
  ListingComponentProps,
  ListingDataProps,
  ListingDetailsProps,
} from './types';
import {
  getQueryFilterValueForGraphQL,
  getSortValueForGraphQL,
  getValueFromObject,
  mapToObjectArray,
} from './utils';

export function ListingComponent({
  entity,
  headerTitle,
  callOnRowClick,
  errorTitle,
  callOnCellClick,
  handleActionItemClick,
  HeaderComponent,
  getListDataQuery,
  queryListData,
  isGraphQL = false,
  permissions,
  subModules,
  isCreateDisable,
  refreshListData = false,
  setRefreshListData,
}: ListingComponentProps) {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search);
  const filterParams = searchParams.get('filters');
  const pageParams = searchParams.get('currentPage');
  const [isFetching, setIsFetching] = useState(true);
  const [subscriptionPlan, setSubscriptionPlan] = useAtom(subscriptionPlanAtom);

  const { checkPermissionsToEnable } = useCheckPermissions({
    entityList: [entity],
    allowedPermission: [],
  });

  const { isHide: isImportDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.create],
    entityList: [entity],
  });

  const plan = JSON.parse(window.sessionStorage.getItem('userPlan') ?? '{}');

  const [isListDataFetching, setIsListDataFetching] = useState(true);
  const { openWithProps: openRuleTemplatePopup } = useLayer(
    <RuleTemplatePopup
      title="Create Rule"
      type="RULE_TEMPLATE"
      selectedTab={0}
      subModules={subModules}
    />
  );
  const [gettingStartedCollapsed, setGettingStartedCollapsed] = useState(true);
  const { sendEventToGTM } = useSendEventToGTM();
  const ref = useRef<PopoverMethods>(null);

  const { axiosPrivate } = useAxiosPrivate();
  const [listingData, setListingData] = useState<ListingDataProps>({
    totalPages: 0,
    totalCount: 0,
    data: [],
  });
  const [listingDetails, setListingDetails] = useState<ListingDetailsProps>({
    allFilters: [],
    appliedFilters: null,
    currentPage: 0,
    entity,
    endpoint: {},
    tableHeaders: [],
  });

  const importModalEntityType = useMemo(() => {
    switch (entity) {
      case 'rules':
        return 'rule';
      default:
        return entity;
    }
  }, [entity]);

  const { openWithProps: openImportModal } = useLayer(
    <ImportEntityModal entityType={importModalEntityType} from="import_list" />
  );

  useEffect(() => {
    // fetch the filters from the url.
    void getAllFilters();
  }, []);

  useEffect(() => {
    void getAccountUsageDetails();
  }, [listingData]);

  const getAccountUsageDetails = async () => {
    const response = await axiosPrivate.get<{
      data: SubscriptionPlanType;
    }>(`/plan/usage`);

    if (!_isNil(response.data.data)) {
      void setSubscriptionPlan({
        ...response.data.data,
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        planName: response.data.data.plan.name,
      });
    }
  };

  useEffect(() => {
    if (!_isNil(queryListData) && !_isUndefined(listingDetails.endpoint)) {
      const updatedListData = JSON.parse(JSON.stringify(listingData));

      updatedListData.data = !_isUndefined(listingDetails.endpoint)
        ? queryListData[listingDetails.endpoint.query]?.data
        : [];

      updatedListData.totalPages = Math.ceil(
        queryListData[listingDetails.endpoint.query].paginationInfo
          .totalRecord / LISTING_PAGE_SIZE
      );

      updatedListData.totalCount =
        queryListData[listingDetails.endpoint.query].paginationInfo.totalRecord;

      setListingData(updatedListData);
      setIsListDataFetching(false);

      if (updatedListData.totalPages > 0) {
        setGettingStartedCollapsed(true);
      }
    }
  }, [queryListData]);

  useEffect(() => {
    const updatedPage = !_isNil(pageParams) ? parseInt(pageParams, 10) : 0;

    if (!_isNil(filterParams) && !isFetching) {
      const appliedFilters = JSON.parse(filterParams);
      updateFilters(appliedFilters, updatedPage);
    } else if (!isFetching && _isNil(filterParams)) {
      updateFilters({}, updatedPage);
    }
  }, [filterParams, isFetching, pageParams]);

  useEffect(() => {
    if (!isFetching && !_isNil(listingDetails.appliedFilters)) {
      if (isGraphQL) {
        void getListingDataForGraphQL();
      } else {
        void getListingDataForRestApi();
      }
    }
  }, [JSON.stringify(listingDetails), isFetching]);

  useEffect(() => {
    if (!isFetching && refreshListData) {
      if (isGraphQL) {
        void getListingDataForGraphQL();
      } else {
        void getListingDataForRestApi();
      }

      if (typeof setRefreshListData === 'function') {
        setRefreshListData(false);
      }
    }
  }, [refreshListData, isFetching]);

  const getAllFilters = async () => {
    const configResponse = await axiosPrivate.get(
      `/common/schema/${listingDetails.entity as string}`
    );

    const updatedListingDetails = {
      ...listingDetails,
      allFilters: mapToObjectArray(configResponse.data.data.filters),
      filterObject: configResponse.data.data.filters,
      endpoint: configResponse.data.data.endpoints,
      tableHeaders: mapToObjectArray(configResponse.data.data.headers),
    };
    setIsFetching(false);
    setListingDetails(updatedListingDetails);
  };

  const openTemplatePopup = () => {
    openRuleTemplatePopup({});
    sendEventToGTM({
      event: 'exp_tmp_no_rule',
    });
  };

  const getListingDataForRestApi = async () => {
    const queryString: string = Object.keys(listingDetails.appliedFilters)
      .map((key) => {
        const value = listingDetails.appliedFilters[key];

        if (key === 'sort') {
          const sortString = Object.entries(value)
            .map(([key, keyValue]) => `${key}:${keyValue as string}`)
            .join(',');

          return `${key}=${sortString}`;
        }

        if (typeof value === 'object') {
          return `${key}=${Object.keys(value).join(',')}`;
        }

        if (value instanceof Date) {
          return `${key}=${value as unknown as string}`;
        }

        return `${key}=${value as string}`;
      })
      .join('&');

    const pageQueryString = `pageNo=${
      (listingDetails.currentPage ?? 0) + 1
    }&pageSize=${LISTING_PAGE_SIZE}`;

    const finalString = `?${pageQueryString}${
      !_isEmpty(queryString) ? `&${queryString}` : ''
    }`;

    const listingURL = listingDetails.endpoint?.search ?? '';
    const updatedURL = listingURL.replace(
      '{module}',
      listingDetails.entity as string
    );
    try {
      const fetchListingData = await axiosPrivate.get(
        `${updatedURL}${finalString}`
      );
      const totalPages = Math.ceil(
        fetchListingData.data.totalCount / LISTING_PAGE_SIZE
      );
      setListingData({
        totalPages,
        data: fetchListingData.data.data ?? [],
        totalCount: fetchListingData.data.totalCount,
      });

      if (totalPages > 0) {
        setGettingStartedCollapsed(true);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        if ('response' in error) {
          const { response } = error as { response: any };

          const errorObj = response?.data;

          if (!_isNil(errorObj)) {
            if (errorObj.code === 'elastic_not_enabled') {
              setListingData({
                totalPages: 1,
                data: [],
                totalCount: 0,
              });
            }
          } else {
            toasts.error(error.message, 'error-toast');
          }
        }
      }
    } finally {
      setIsListDataFetching(false);
    }
  };

  const getListingDataForGraphQL = async () => {
    if (typeof getListDataQuery === 'function') {
      const { filterObject } = listingDetails;

      let queryFilter: Record<string, any> = {};
      let sortFilter: Record<string, any> = {};

      if (!_isUndefined(filterObject)) {
        Object.keys(listingDetails.appliedFilters).forEach((key) => {
          const value = listingDetails.appliedFilters[key];

          if (key === 'sort') {
            sortFilter = getSortValueForGraphQL(value, sortFilter);
          } else {
            queryFilter = getQueryFilterValueForGraphQL(
              value,
              key,
              queryFilter,
              filterObject
            );
          }
        });
      }

      setIsListDataFetching(true);

      await getListDataQuery({
        variables: {
          page: (listingDetails.currentPage ?? 0) + 1,
          perPage: 10,
          filters: queryFilter,
          sort: {
            ...sortFilter,
            updatedAt: -1,
          },
        },
        fetchPolicy: 'no-cache',
      });
    }
  };

  const removeFilter = (filter: string, value: string) => {
    const appliedFiterObj = { ...listingDetails.appliedFilters };

    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete appliedFiterObj[filter][value];

    void delayedNavigateTo(appliedFiterObj, 0);
  };

  const handleFiltersChange = (event: any, name: string, type: string) => {
    if (type === 'dropdown') {
      const appliedFiterObj = { ...listingDetails.appliedFilters };
      const { filterObject } = listingDetails;

      if (event.currentTarget.checked === true) {
        if (
          !_isUndefined(filterObject) &&
          filterObject[name].selection === 'multi'
        ) {
          appliedFiterObj[name] = {
            ...appliedFiterObj[name],
            [event.currentTarget.name]: event.currentTarget.checked,
          };
        } else {
          appliedFiterObj[name] = {
            [event.currentTarget.name]: event.currentTarget.checked,
          };
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete appliedFiterObj[name][event.currentTarget.name];
      }
      void delayedNavigateTo(appliedFiterObj, 0);
    } else if (type === 'datetime') {
      const appliedFiterObj = {
        ...listingDetails.appliedFilters,
      };
      appliedFiterObj[name] = !_isNil(event)
        ? new Date(event).toISOString()
        : '';
      void delayedNavigateTo(appliedFiterObj, 0);
    } else if (type === 'searchBox') {
      const appliedFiterObj = {
        ...listingDetails.appliedFilters,
      };
      appliedFiterObj[name] = event;
      void delayedNavigateTo(appliedFiterObj, 0);
    } else if (type === 'sort') {
      const appliedFiterObj = {
        ...listingDetails.appliedFilters,
      };

      const getSortValue = () => {
        if (
          !_isNil(appliedFiterObj[type]) &&
          !_isNil(appliedFiterObj[type][name])
        ) {
          return appliedFiterObj[type][name] === '1' ? '-1' : '1';
        }

        return '1';
      };

      appliedFiterObj[type] = {
        ...appliedFiterObj[type],
        [name]: getSortValue(),
      };

      void delayedNavigateTo(appliedFiterObj, 0);
    }
  };

  const updateFilters = (
    appliedFiterObj: AppliedFiltersProps,
    updatedPage: number
  ) => {
    setListingDetails({
      ...listingDetails,
      appliedFilters: appliedFiterObj,
      currentPage: updatedPage,
    });
  };

  const navigateTo = (appliedFilters: any, currentPage: number) => {
    navigate(
      `${pathname}?filters=${encodeURIComponent(
        JSON.stringify(appliedFilters)
      )}&currentPage=${currentPage}`
    );
  };

  const delayedNavigateTo = useCallback(debounce(navigateTo, 5), []);

  const onPageChange = (pageSize: number, pageNumber: number) => {
    if (pageNumber !== listingDetails.currentPage) {
      void delayedNavigateTo(listingDetails.appliedFilters, pageNumber);
    }
  };

  const handleRowClick = (row: Record<string, any>) => {
    callOnRowClick(row, listingDetails);
  };

  const handleCellClick = (
    cell: Record<string, any>,
    index: number,
    listType: string
  ) => {
    callOnCellClick(cell, index, listType, listingDetails);
  };

  const memoizeTableColum = useMemo(() => {
    return getTableColumns(
      entity,
      listingDetails,
      permissions,
      handleActionItemClick,
      checkPermissionsToEnable
    );
  }, [
    entity,
    listingDetails,
    permissions,
    handleActionItemClick,
    checkPermissionsToEnable,
  ]);

  const handleGSExpander = () => {
    sendEventToGTM({
      event: gettingStartedCollapsed ? 'lb_closed' : 'lb_expanded',
      action: 'click',
      type: entity,
    });
    setGettingStartedCollapsed(!gettingStartedCollapsed);
  };

  const importComponentId = getValueFromObject(plan, `plan.import.componentId`);

  return (
    <>
      <Header
        content={
          !_isNil(HeaderComponent) ? (
            <HeaderComponent />
          ) : (
            <Inline gutter={25}>
              <Typography name="heading1" fontWeight={700}>
                {headerTitle}
              </Typography>
            </Inline>
          )
        }
      />
      {!_isNil(gettingStartedContentBlock[entity]) && (
        <ListingBlock
          entity={entity}
          title={entityTitles[entity]}
          collapsed={gettingStartedCollapsed}
          blockData={gettingStartedContentBlock[entity] ?? []}
          updateExpanderState={handleGSExpander}
          subModules={subModules}
        ></ListingBlock>
      )}
      <ListingContainer>
        <Stack gutter="1rem">
          <FilterContainer>
            {!_isNil(listingDetails.appliedFilters) && (
              <RenderFilters
                filters={listingDetails}
                handleFiltersChange={handleFiltersChange}
                ref={ref}
              />
            )}
            {['rule', 'workflow'].includes(importModalEntityType) && (
              <Button
                disabled={isImportDisable}
                onClick={() => openImportModal({})}
                trailingIcon={<TbDownload />}
                appearance="filled"
              >
                <div
                  id={importComponentId}
                  data-premium-component-id={importComponentId}
                  data-premium-component-trigger={getValueFromObject(
                    plan,
                    `plan.import.trigger`
                  )}
                >
                  Import
                </div>
              </Button>
            )}
          </FilterContainer>
          <PadBox padding={['0rem', '1.6rem']}>
            {!_isNil(listingDetails.appliedFilters) &&
              renderAppliedFilters(
                listingDetails.allFilters,
                listingDetails.appliedFilters,
                removeFilter
              )}
          </PadBox>
        </Stack>
        {!_isNil(listingData.data) && listingData.data.length > 0 && (
          <Stack gutter={'1rem'}>
            <PadBox padding={['0rem', '1.6rem']}>
              <Typography>
                Showing{' '}
                {listingData.totalPages > 1 &&
                  listingData.totalPages - 1 !== listingDetails.currentPage && (
                    <>
                      {(listingDetails.currentPage as number) *
                        LISTING_PAGE_SIZE +
                        1}{' '}
                      -{' '}
                      {((listingDetails.currentPage as number) + 1) *
                        LISTING_PAGE_SIZE}{' '}
                      of {listingData.totalCount} Items
                    </>
                  )}
                {(listingData.totalPages === 1 ||
                  _isNil(listingData.totalPages) ||
                  listingData.totalPages - 1 ===
                    listingDetails.currentPage) && (
                  <>
                    {listingData.totalCount} of {listingData.totalCount} Items
                  </>
                )}
              </Typography>
            </PadBox>
            <NewTable
              entity={entity}
              gettingStartedCollapsed={gettingStartedCollapsed}
              columns={memoizeTableColum}
              data={listingData.data}
              totalPages={listingData.totalPages}
              currentPage={listingDetails.currentPage as number}
              onPageChange={onPageChange}
              onRowClicked={handleRowClick}
              onCellClicked={handleCellClick}
              handleFiltersChange={handleFiltersChange}
              listingDetails={listingDetails}
              subscriptionPlan={subscriptionPlan}
            />
          </Stack>
        )}
        {listingData.data.length === 0 && !isListDataFetching && (
          <NoDataContainer>
            <BannerBox
              title={errorTitle}
              subTitle=""
              bannerImage="no_content.png"
              buttonProps={{
                buttonText: '',
              }}
              entity={entity}
              openTemplate={openTemplatePopup}
              isCreateDisable={isCreateDisable}
            />
          </NoDataContainer>
        )}
        {(isFetching || isListDataFetching) && (
          <NoDataContainer>
            <Inline justify="center">
              <Spinner size="small" />
            </Inline>
          </NoDataContainer>
        )}
      </ListingContainer>
    </>
  );
}
